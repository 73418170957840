/* [[ Nav & Header ]] */

/*
    This is a less conversion of the menu
*/

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

.navbar-fixed-top {
    position: fixed;
} 

.container > .navbar-collapse {
    margin: 0 !important;
}

.navbar {
    margin: 0;
    background-image: none;
    border: none;
    box-shadow: none;
    position: absolute;

    .container {
        .navbar-header {
            height: 75px;

            .navbar-brand {
                height: auto;
                padding: 8px 15px !important;

                &:hover {
                    opacity: 0.7;
                    transition: opacity 0.3s;
                }
                // mobile symbol only
                @media (max-width: $sm) { // not mob first
                    background-image: url(../../images/logo_symbol_only.png);
                    background-repeat: no-repeat;
                    background-size: initial;
                    height: 62px;
                    width: 62px;
                    margin: 13px 0 0 15px;
                }

                @media (max-width: $xl) { // not mob first
                    margin-left: 0.5rem;
                }

                img {
                    @media (max-width: $sm) { // not mob first
                        display: none;
                    }
                }
            }

            .navbar-toggle {
                border: 0;
                border-radius: 0;
                padding: 10px 9px;
                margin: 18px 15px 18px 0;
                background: transparent;

                @media (max-width: $xl) { // not mob first
                    margin-right: 1.6rem;
                }

                .icon-bar {
                    background-color: $duckegg !important;
                    position: relative;
                    transition: all 500ms ease-in-out;
                    width: 27px;
                    height: 3px;
                }
            }
            /* Animates to a cross icon */
            .navbar-toggle.active {

                .icon-bar.first {
                    top: 7px !important;
                    transform: rotate(45deg) !important;
                }

                .icon-bar.second {
                    background-color: transparent !important;
                }

                .icon-bar.third {
                    top: -7px;
                    transform: rotate(-45deg);
                }
            }
        }

        .navbar-collapse {
            max-height: calc(100vh - 75px); // minus the navbar height
            .nav {
                margin-top: 0;
                margin-bottom: 0;

                li {
                    padding: 0;

                    a {
                        text-shadow: none;
                        text-transform: uppercase;
                        font-weight: bold !important;
                        padding: 15px !important;
                        font-size: 16px !important;
                        font-weight: 600;

                        @media (min-width: $xl) {
                            border-bottom: none;
                            padding: 28px 12px !important;
                        }
                    }

                    .tertiary-btn {
                        border-radius: 5px;
                    }

                    .primary-btn {
                        border-radius: 5px;
                        text-align: center;
                        display: inline-block;
                        font-size: 18px;
                        border: none;
                        cursor: pointer;
                        text-decoration: none!important;
                        font-weight: 600;
                        color: #fff!important;
                        background-color: #0bb5b5;
                        box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
                    }

                    .secondary-btn {
                        color: $duckegg !important;
                        background-color: $duckegg;
                        border-radius: 5px;

                        @media (min-width: $xl) {
                            color: $white !important;
                        }

                        &:hover {
                            background-color: $duckegg-tint-50; /* Old browsers */
                                color: $mint !important;
                            @media (min-width: $xl) {
                                color: $white !important;
                            }
                        }
                    }
                }

                #your-orders {
                    text-transform: none;

                    &:hover {
                        color: $duckegg-tint-50 !important;
                    }
                }

                .dropdown {
                    a {
                        .fa-chevron-down {
                            font-size: 12px;
                            position: relative;
                            bottom: 2px;
                        }
                    }

                    .dropdown-menu {
                        border: none;
                        padding: 0;
                        border-radius: 0;
                        top: 60px;

                        li {
                            a {
                                padding: 15px 10px 15px 20px;
                                font-weight: 600;
                                color: $duckegg !important;
                                background-image: none;
                                text-transform: unset;

                                @media (min-width: $xl) {
                                    color: $duckegg !important;
                                    padding: 15px 10px !important;
                                }

                                &:hover {
                                    @media (min-width: $xl) {
                                        color: $mint !important;
                                        background-color: $mint-opacity-20 !important;
                                    }

                                    svg {
                                        color: $white;
                                    }
                                }

                                svg {
                                    color: $grey-tint-50;
                                }
                            }
                        }
                    }
                }

                .dropdown.open {
                    .dropdown-toggle {
                        background-image: none;
                        background-color: transparent;
                    }
                }
            }

            .navbar-right {
                li.no-hover {
                    a {
                        &:hover {
                            background-color: transparent !important; // ;
                        }
                    }
                }
            }

            .navbar-right > li > a {
                margin: 0;

                @media (min-width: $xl) {
                    margin: 18px 3px;
                    padding: 10px 15px !important;
                }

                &.tertiary-btn {
                    padding: 8px 15px !important;
                }
            }

            #sign-up-btn {
                padding: 10px 15px !important;
            }

            .navbar-right {
                margin-top: 0;
                margin-bottom: 0;
                padding-right: 15px;

                .dropdown-toggle {
                    @media (min-width: $xl) {
                        padding: 10px 15px !important;
                    }
                }

                .primary-btn--outline, .tertiary-btn--outline, .secondary-btn {
                    background-color: $white;
                }

                .tertiary-btn--outline {
                    color: $duckegg !important;
                    border: solid 2px $duckegg;

                    &:hover {
                        background-color: $duckegg-tint-70 !important;
                        border-color: $duckegg-tint-70 !important;
                        color: $mint !important;

                        @media (min-width: $xl) {
                            color: $white !important;
                        }
                    }
                }

                .btn {
                    text-transform: unset;
                }

                .primary-btn, .secondary-btn, .tertiary-btn {
                    @media (max-width: $xl) { /* max-width - not mob first */
                        background-color: transparent !important;
                        border-radius: 0 !important;
                        text-align: left !important;
                        font-weight: 600;
                        display: block !important;
                        margin: 0 !important;
                        padding: 15px 10px !important;
                        border-top: none !important;
                        border-left: none !important;
                        border-right: none !important;
                        border-bottom: 1px solid #f6f6f6;
                        color: $duckegg !important;

                        &:hover {
                            background-color: transparent !important;
                            background: transparent !important;
                            box-shadow: none;
                        }
                    }
                }
            }
        }
    }
}


#navbar-container {
    .navbar {
        border-bottom: $grey-tint-25 1px solid;
        background-color: $white;
        min-height: 50px;
        margin-bottom: 20px;
        right: 0;
        left: 0;
        top: 0;
        z-index: 1030;
        border-bottom: 1px solid #d5d7d6;

        .container {
            .navbar-collapse {
                .nav {
                    li {
                        a {
                            color: $duckegg;

                            @media (max-width: $xl) { /* max-width - not mob first */
                                border-bottom: 1px solid #f6f6f6;
                            }


                            &:hover {
                                color: $mint;
                                transition: color 0.3s;
                            }
                        }

                        #your-orders {
                            color: $blueberry-tint-50;
                        }
                    }
                }

                .navbar-right {
                    .primary-btn, .secondary-btn, .tertiary-btn {
                        @media (max-width: $xl) { /* max-width - not mob first */
                            //color: @grey !important;
                            text-transform: uppercase;
                            box-shadow: none !important;
                            padding: 15px 10px !important;

                            &:hover {
                                background-color: transparent !important;
                                background: transparent !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Make dropdown menu open on hover
@media (min-width: $xl) {
    ul.nav li.dropdown:hover > ul.dropdown-menu {
        display: block;
    }

    .navbar-right .dropdown-menu {
        right: unset !important;
    }
}

.navbar .container .navbar-collapse .nav .dropdown .dropdown-menu li a {
    text-transform: uppercase !important;
    padding: 15px 20px !important;
    font-weight: 600;
    color: #0bb5b5!important;
    background-image: none;
    text-transform: unset;
}

.navbar .container .navbar-collapse .nav li .primary-btn {
    color: #0bb5b5!important;

    @media (min-width: $xl) {
        color: #fff!important;
    }
}

.nav li .primary-btn:hover {
    background-color:#54CBCB !important;
}

// .nav .secondary-btn {
//     background-color: transparent !important;
//     text-transform: uppercase !important;
// }

#navbar-container .navbar .container .navbar-collapse .nav li .nav-pipe {
    border: #0BB5B5 1px solid;
    padding: 0 !important;
    height: 2.5rem;
    display: none;

    @media (min-width: $xl) {
        display: block;
    }
}
