﻿/* ===============
      ACCORDION
   ===============
    // Block is named feijoa-accordion because accordion default styling already comes from Foundation
*/

.feijoa-accordion {

    &__container {
        @include xy-grid-container;
    }

    &__inner-container {
        @include xy-grid;
    }

    &__list {
        @include accordion-container;
        @include xy-cell(12);
        border-bottom: rem-calc(1) solid $grey-tint-25;
        margin-bottom: 0;
        background-color: transparent;
    }

    &__item {
        @include accordion-item;
        border-top: rem-calc(1) solid $grey-tint-25;
    }

    &__heading-container {
        @include accordion-title;
        @include xy-cell();
        display: flex;
        align-items: flex-start;
        padding: rem-calc(30 0);

        @include breakpoint(medium) {
            @include xy-cell(11);
            @include xy-cell-offset(1);
            padding: rem-calc(40 0);
        }

        &:focus {
            outline: none;
            color: $blueberry;
        }

        &:hover {
            color: $mint;
            background-color: transparent;

            &:before {
                color: $mint;
            }
        }

        &:focus {
            background-color: transparent;
        }

        &:before {
            right: unset;
            top: rem-calc(5);
            color: $duckegg;
            font-size: rem-calc(28);
            position: relative;

            @include breakpoint(medium) {
                width: 8.3333%;
                text-align: center;
                left: -8.3333%;
                position: absolute;
                top: rem-calc(51);
                font-size: rem-calc(33);
            }

            @include breakpoint(large) {
                font-size: rem-calc(35);
            }
        }
    }

    &__heading {
        margin-left: rem-calc(15);
        line-height: $global-lineheight;
        font-size: rem-calc(18);

        @include breakpoint(medium) {
            font-size: rem-calc(26);
            margin-left: 0;
        }

        @include breakpoint(large) {
            font-size: rem-calc(30);
        }
    }

    &__content {
        @include accordion-content;
        @include xy-cell(11);
        @include xy-cell-offset(1);
        font-size: rem-calc(16);
        background-color: transparent;

        @include breakpoint(medium) {
            font-size: rem-calc(18);
        }

        @include breakpoint(large) {
            font-size: rem-calc(22);
            padding-bottom: rem-calc(50);
        }

        h1, h2, h3, h4, h5, h6 {
            margin-bottom: rem-calc(20);
        }

        p {
            margin-bottom: rem-calc(40);
        }

        ul, ol {
            margin-bottom: rem-calc(28);
        }

        li {
            padding-bottom: rem-calc(12);
        }

        table {
            border-radius: unset;
            margin: 0;
            padding-bottom: rem-calc(10);

            @include breakpoint(small only) {
                border-right: $spinach-tint-50 solid rem-calc(2);
            }

            @include breakpoint(medium down) {
                display: block;
                overflow-x: scroll;
            }

            thead {
                background-color: transparent;
                border: none;
            }

            thead tr {
                color: $blueberry;
            }

            tbody {
                border: none;
                background-color: transparent;
            }

            tbody td {
                @include body-copy;

                @include breakpoint(small) {
                    min-width: rem-calc(100);
                }
            }

            tbody tr:nth-child(odd) {
                background-color: $white;
            }

            tbody tr:nth-child(even) {
                background-color: $spinach-tint-13;
            }

            tbody tr:first-of-type {
                background-color: $white;
            }
        }
    }

    &__button {
        margin: rem-calc(30 15 0);

        @include breakpoint(small only) {
            width: 100%;
        }

        @include breakpoint(medium) {
            margin: rem-calc(50 15 0);
        }

        @include breakpoint(large) {
            margin: rem-calc(60 15 0);
        }
    }
}