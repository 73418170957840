﻿/* =======================
      DOWNLOADS SECTION
   =======================
*/

.downloads-section {
    background-color: $duckegg;
    padding: rem-calc(30) 0;

    @include breakpoint(medium) {
        padding: rem-calc(60) 0;
    }

    @include breakpoint(large) {
        padding: rem-calc(100) 0;
    }

    &__container {
        @include xy-grid-container;
    }

    &__inner-container {
        @include xy-grid;
        position: relative;
    }

    &__heading {
        color: $white;
        @include xy-cell();

        @include breakpoint(large) {
            @include xy-cell(4);
            padding: 0;
        }
    }

    &__parent-list-container {
        @include xy-cell();
        margin: 0;
        width: 100%;

        @include breakpoint(medium) {
            @include xy-cell(12);
            margin: 0;
        }

        @include breakpoint(large) {
            @include xy-cell(8);
            margin: 0;
        }
    }

    &__list-container {
        margin-top: rem-calc(30);
        @include xy-grid;

        @include breakpoint(medium) {
            margin-top: rem-calc(40);
        }

        @include breakpoint(large) {
            margin-top: rem-calc(50);
        }

        &:nth-child(1) {
            @include breakpoint(large) {
                margin-top: rem-calc(0);
            }
        }
    }

    &__info-alert {
        margin-bottom: rem-calc(15);

        @include breakpoint(medium) {
            margin-bottom: rem-calc(30);
        }
    }

    &__list-label-container {
        width: 100%;
        padding: rem-calc(0 15);
        margin-bottom: rem-calc(10);
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        @include breakpoint(medium) {
            margin-bottom: rem-calc(30);
        }
    }

    &__list-label {
        color: $white;
        margin-bottom: 0;
    }

    &__toggle-button {
        margin-bottom: 0;
        float: right;
        margin-top: rem-calc(10);

        @include breakpoint(medium) {
            float: none;
            margin-top: 0;
        }
    }
    // Sizing here any other styles go in button specific file _download-button.scss
    .download-button {
        @include xy-cell();
        margin-bottom: rem-calc(15);

        @include breakpoint(medium) {
            @include xy-cell(6);
            margin-bottom: rem-calc(20);
        }
    }

    &--light {
        background-color: $spinach-tint-13;

        .downloads-section__heading {
            color: $blueberry;
        }

        .downloads-section__list-label {
            color: $blueberry;
        }
    }
}