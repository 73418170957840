﻿/* ===========
      AWARDS
   ===========
*/

.awards {
    background-color: $blueberry;
    padding: rem-calc(20) 0;
    position: relative;
    overflow: hidden;

    @include breakpoint(medium) {
        padding: rem-calc(30) 0;
    }

    @include breakpoint(large) {
        padding: rem-calc(80) 0;
    }

    &__container {
        @include xy-grid-container;
    }

    &__inner-container {
        @include xy-grid;
        position: relative;
    }

    &__structure {
        position: absolute;
        opacity: 0.4;

        &--top {
            height: rem-calc(280);
            top: rem-calc(-150);
            left: rem-calc(0);

            @include breakpoint(medium) {
                height: rem-calc(310);
            }

            @include breakpoint(large) {
                height: rem-calc(450);
                top: rem-calc(-250);
            }
        }

        &--bottom {
            height: rem-calc(250);
            bottom: rem-calc(100);
            right: rem-calc(0);

            @include breakpoint(medium) {
                bottom: rem-calc(0);
                height: rem-calc(280);
            }

            @include breakpoint(large) {
                height: rem-calc(420);
            }
        }
    }

    &__item {
        @include xy-cell();
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint(medium) {
            @include xy-cell(6);
        }

        @include breakpoint(large) {
            @include xy-cell(3);
        }
    }

    &__img {
        padding: rem-calc(15) 0;

        @include breakpoint(large) {
            padding: 0;
        }
    }
}