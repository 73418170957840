﻿/* ================
      OFFER CARD
   ================
*/

@import '../feijoa-variables';

.offer-card {
    box-shadow: $initial-shadow;
    transition: all 0.3s ease-in-out;
    background-color: $white;
    display: block;
    position: relative;

    @include breakpoint(large) {
        min-height: rem-calc(340) !important;
    }

    * {
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        box-shadow: $hover-shadow;
        top: rem-calc(-2);
        cursor: pointer;

        .offer-card {
            &__overlay {
                opacity: 1;
            }
        }
    }

    &__ribbon {
        top: 0;
        left: 0;
        width: 100%;
        padding: 0.6rem 1rem;
        text-align: center;
        text-transform: uppercase;
        position: absolute;
        color: $white;

        &--open-for-investment {
            background-color: $feijoa;
        }

        &--open-for-reservation {
            background-color: $mentos;
        }

        &--reservation-full {
            background-color: $grey-tint-50;
        }

        &--coming-soon {
            background-color: $oreo;
        }
    }

    &__inner-container {
        display: block;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        padding: rem-calc(68) rem-calc(25) rem-calc(25) rem-calc(25);

        @include breakpoint(large) {
            min-height: rem-calc(368) !important;
        }
    }

    &__body {
        margin-top: rem-calc(30);
        min-height: rem-calc(81);

        @include breakpoint(large) {
            margin-top: rem-calc(40);
        }
    }

    &__footer {
        margin: rem-calc(25) 0;

        @include breakpoint(large) {
            margin: rem-calc(30) 0;
        }
    }

    &__h4,
    &__h3 {
        color: $blueberry !important;
        margin: 0;
    }

    &__footer-copy,
    &__body-copy {
        @include body-copy;
        color: $blueberry;
        margin: 0;
    }

    &__body-copy {
        margin-top: rem-calc(5);
    }

    &__footer-copy {
        max-width: 80%;

        @include breakpoint(large) {
            max-width: 85%;
        }
    }

    &__arrow {
        position: absolute;
        right: rem-calc(30);
        bottom: rem-calc(0);
        right: rem-calc(25);

        @include breakpoint(large) {
            right: rem-calc(30);
        }

        .material-icons {
            font-size: rem-calc(24);
            color: $duckegg;

            @include breakpoint(large) {
                font-size: rem-calc(30);
            }
        }
    }

    &__overlay {
        background-color: $blueberry-opacity-80;
        position: absolute;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        height: 100%;
        opacity: 0;
        padding: rem-calc(30 25);

        @include breakpoint(large) {
            padding: rem-calc(35 30);
        }
    }

    &__overlay-copy {
        color: $white;
        text-align: center;
        @include body-copy;
        @include body-copy--bold;
    }

    &__overlay-button-group {
        display: flex;
        flex-direction: column;
        margin-top: rem-calc(10);
        margin-bottom: 0;
        min-width: 100%;

        @include breakpoint(large) {
            margin-top: rem-calc(15);
            min-width: 70%;
        }
    }

    &__overlay-button {
        width: 100%;
        margin-top: rem-calc(10) !important;
        display: block;

        @include breakpoint(large) {
            margin-top: rem-calc(15) !important;
        }
    }
}