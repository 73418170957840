﻿/* ===============================
      CONTAINED TILE COLLECTION
   ===============================
    Swipable
*/

.contained-tile-collection {
    &__container {
        @include xy-grid-container;
        overflow: visible;
        padding: 0 0 0 rem-calc(20);
        margin-right: rem-calc(-60);

        @include breakpoint(medium) {
            padding: 0 rem-calc(15);
            margin: 0 auto;
        }
    }

    &__inner-container {
        @include xy-grid;
        position: relative;
    }

    .tile {
        min-width: unset;
        min-height: unset;
        margin-bottom: 0;

        @include breakpoint(small only) {
            margin: 0 rem-calc(10) 0 0;
        }
    }

    &--img-dom {
        .slick-arrow {
            top: 33%;
        }
    }

    &--bottom-padding {
        padding-bottom: rem-calc(40);

        @include breakpoint(medium) {
            padding-bottom: rem-calc(60);
        }

        @include breakpoint(large) {
            padding-bottom: rem-calc(100);
        }
    }

    &--no-slider {
        .tile {
            @include breakpoint(small) {
                margin: 0 rem-calc(25) rem-calc(20) 0;
            }
        }

        .contained-tile-collection {
            &__container {
                @include breakpoint(small only) {
                    padding: 0;
                    margin: 0 auto;
                }
            }

            &__inner-container {
                @include breakpoint(small only) {
                    justify-content: center;
                } 
            }
        }


        .tile__background-img {
            @include breakpoint(medium) {
                height: rem-calc(118);
            }

            @include breakpoint(small only) {
                height: rem-calc(240) !important;
            }
        }
    }
}

.slick {
    &-arrow {
        display: inline-block;
        z-index: 3;
        position: absolute;
        top: 40%;
        color: $duckegg;
        background-color: $white;
        box-shadow: 0 0 0 1px rgba(0,0,0,0.04), 0 4px 8px 0 rgba(0,0,0,0.20);
        border-radius: 100%;
        transition: all 0.3s ease-in-out;
        padding: rem-calc(4 5);
        border: rem-calc(1) solid $white;
        cursor: pointer;

        .material-icons {
            font-size: rem-calc(30);
            position: relative;
            top: rem-calc(1);
        }

        @media (hover: none) {
            display: none !important;
        }

        @include breakpoint(small only) {
            display: none !important;
        }

        &:hover {
            color: $mint;
        }

        &:focus {
            outline: none;
        }

        &:active:focus {
            border-color: $duckegg;
        }
    }

    &-prev {
        left: rem-calc(-5);
    }

    &-next {
        right: rem-calc(-5);
    }

    &-track {
        margin-left: 0 !important;
    }

    &-list {
        overflow: visible !important;
    }

    &-slide {
        opacity: 0.2;
        pointer-events: none;
    }

    &-active {
        opacity: 1;
        pointer-events: all;
    }
}