/* ======================
      CLOSABLE CALLOUT
   ======================
*/

@import '../feijoa-variables';
@import '../../../../node_modules/foundation-sites/scss/foundation.scss';

.callout_message {
  font-size: rem-calc(14) !important;
  font-weight: 700;
  display: flex;
  margin-right: rem-calc(-23);
  margin-bottom: rem-calc(20);

  .material-icons {
    font-size: rem-calc(24);
    margin-left: rem-calc(3) !important;
    margin-right: rem-calc(3) !important;
  }
}


.callout {
  border-radius: rem-calc(0);

  .callout_message {
    font-size: rem-calc(14) !important;
    font-weight: 700;
    display: flex;
    margin-right: rem-calc(-23);
    margin-bottom: rem-calc(20);

    .material-icons {
      font-size: rem-calc(24);
      margin-left: rem-calc(3) !important;
      margin-right: rem-calc(3) !important;
    }
  }


  &__container {
    @include xy-grid-container;

    @include breakpoint(small only) {
      padding-left: unset;
      padding-right: unset;
    }
  }

  &__inner-container {
    @include xy-grid;
    position: relative;
    flex-direction: column;
    max-width: unset;

    @include breakpoint(medium) {
      align-items: center;
      max-width: 64%;
      margin: auto;
    }
  }

  &__heading {
    flex: 1;
    width: 100%;
  }

  &__body {
    flex: 1;
    width: 100%;

    p {
      margin-bottom: rem-calc(15);
      width: 100%;

      &:last-of-type {
        margin: 0;
      }
    }

    a {
      overflow-wrap: break-word;
    }
  }

  &__button-container {
    display: flex;
    flex-direction: column;
    margin-top: rem-calc(20);
    align-items: center;

    @include breakpoint(small only) {
      width: 100%;
    }

    @include breakpoint(medium) {
      flex-direction: row;
      /*margin-top: rem-calc(30);*/
    }
    /*@include breakpoint(large) {
            margin-top: rem-calc(50);
        }*/
    button {
      @include breakpoint(small only) {
        width: 100%;
      }
    }
  }

  @include breakpoint(medium) {
    justify-content: center;
  }
}
