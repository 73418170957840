﻿/* =============
      CAPTION
   =============
*/

.caption {
    display: none;
    padding: rem-calc(6 12);
    position: absolute;
    background-color: $white-opacity-40;

    @include breakpoint(medium) {
        display: inline;
        right: rem-calc(0);
        top: rem-calc(0);
    }
}