﻿/* =====================
      FULL WIDTH TEXT
   =====================
*/

.full-width-text {
    &__container {
        @include xy-grid-container;
    }

    &__inner-container {
        @include xy-grid;
        position: relative;
    }

    &__content {
        color: $blueberry;
        @include xy-cell();

        @include breakpoint(medium) {
            @include xy-cell(12);
        }
    }
}