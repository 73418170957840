﻿/* ====================
      OPTIONS FOOTER
   ====================
*/

.options-footer {
    padding: rem-calc(20) 0 0 0;

    @include breakpoint(medium) {
        padding: rem-calc(30) 0 0 0;
    }

    @include breakpoint(large) {
        padding: rem-calc(40) 0 0 0;
    }

    &__container {
        @include xy-grid-container;
    }

    &__inner-container {
        @include xy-grid;
        position: relative;
    }

    &__text {
        @include xy-cell();
        margin-bottom: 0;
        color: $blueberry;

        a {
            text-decoration: underline;
        }

        p {
            margin: 0;
        }
    }
}