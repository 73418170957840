﻿/* ====================
      COOKIE CONSENT
   ====================
*/

.cookie-consent {
    z-index: 999999;

    &__container {

        @include breakpoint(small only) {
            margin: 0 !important;
        }

        @include breakpoint(medium) {
            display: flex;
            justify-content: center;
        }
    }
}