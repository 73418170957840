﻿/* ===============================
      SCANNABLE TILE COLLECTION
   ===============================
*/

.scannable-tile-collection {
    padding: rem-calc(20) 0 0 0;

    @include breakpoint(medium) {
        padding: rem-calc(30) 0 0 0;
    }

    @include breakpoint(large) {
        padding: rem-calc(60) 0 0 0;
    }

    &__container {
        @include xy-grid-container;
    }

    &__inner-container {
        @include xy-grid;
        position: relative;
    }

    .tile__heading {
        min-height: rem-calc(112);

        @include breakpoint(medium) {
            min-height: rem-calc(84);
        }

        @include breakpoint(large) {
            min-height: rem-calc(138);
        }

        @include breakpoint(xlarge) {
            min-height: rem-calc(178);
        }
    }
}