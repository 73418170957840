﻿/* ===============================
      INFO Alert
   ===============================
    - An alert is used to provide a contextual message. This type is to bring attention to important information.
*/

.info-alert {
    background-color: $white-opacity-80;
    margin-left: rem-calc(10);
    margin-right: rem-calc(10);
    padding: rem-calc(15);
    display: flex;
    width: 100%;

    @include breakpoint(medium) {
        margin-left: rem-calc(15);
        margin-right: rem-calc(15);
    }

    @include breakpoint(large) {
        padding: rem-calc(20);
    }

    &__icon {
        color: $duckegg;
        position: relative;
        top: rem-calc(6);
    }

    &__content {
        @include h6;
        color: $blueberry;
        padding-left: rem-calc(15);

        @include breakpoint(large) {
            padding-left: rem-calc(20);
        }

        p {
            margin-bottom: rem-calc(8);

            &:last-of-type {
                margin-bottom: rem-calc(0);
            }
        }

        a {
            text-decoration: underline;
        }
    }
}