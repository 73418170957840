﻿/* ===============
      LOGO LINK
   ===============
*/

.logo-link {
    width: 50%;
    height: rem-calc(180);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: rem-calc(15);
    transition: all ease-in-out 0.3s;
    border-top: rem-calc(3) solid $white;
    border-bottom: rem-calc(3) solid $white;
    margin-top: rem-calc(-3); //Stop borders from overlapping and adding to thickness

    @include breakpoint(medium) {
        width: 33.33333%;
        height: rem-calc(220);
    }

    @include breakpoint(large) {
        width: 25%;
        height: rem-calc(330);
    }

    &:hover {
        box-shadow: $hover-shadow;
        background-color: $white;

        .logo-link__img {
            filter: unset;
        }

        .logo-link__hover-button {
            @include breakpoint(large) {
                opacity: 1;
            }
        }
    }

    &:focus {
        outline: none;
        box-shadow: $hover-shadow;
        background-color: $white;

        .logo-link__img {
            filter: unset;
        }

        .logo-link__hover-button {
            @include breakpoint(large) {
                opacity: 1;
                box-shadow: $hover-shadow !important;
            }
        }
    }

    &:focus:active {
        background-color: $duckegg-tint-50;
        box-shadow: none;

        .logo-link__hover-button {
            @include breakpoint(large) {
                box-shadow: inset 0 10px 20px $duckegg, inset 0 6px 6px $duckegg;
                background-color: $duckegg-tint-70;
                color: $white;
            }
        }
    }

    &__img {
        filter: grayscale(100%) brightness(30%) opacity(65%);
        min-width: 100%;

        @include breakpoint(large) {
            margin-top: rem-calc(60); // Height of hover-button including margin
        }
    }

    &__hover-button {
        display: none;

        @include breakpoint(large) {
            opacity: 0;
            margin: rem-calc(40 0 0);
            display: block;
        }
    }
}