﻿/* ========================
      DUAL COLUMN LAYOUT
   ========================
*/

.dual-column-layout {
    padding-top: rem-calc(50);

    @include breakpoint(medium) {
        padding-top: rem-calc(100);
    }

    @include breakpoint(large) {
        padding-top: rem-calc(130);
    }

    &--bottom-padding {
        padding-bottom: rem-calc(50);

        @include breakpoint(medium) {
            padding-bottom: rem-calc(100);
        }

        @include breakpoint(large) {
            padding-bottom: rem-calc(130);
        }
    }

    &__container {
        @include xy-grid-container;
    }

    &__inner-container {
        @include xy-grid;
        position: relative;
    }

    &__left-col {
        @include xy-cell();

        @include breakpoint(medium) {
            @include xy-cell(5);
        }

        @include breakpoint(large) {
            @include xy-cell(4);
        }
    }

    &__right-col {
        @include xy-cell();
        @include h6;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: $blueberry;

        @include breakpoint(small only) {
            margin-top: rem-calc(15);
        }

        @include breakpoint(medium) {
            @include xy-cell(7);
        }

        @include breakpoint(large) {
            @include xy-cell(8);
        }

        h1, h2, h3, h4, h5, h6 {
            margin-bottom: rem-calc(15);
        }

        p {
            margin-bottom: rem-calc(30);

            &:last-of-type {
                margin: 0;
            }
        }

        ul, ol {
            margin-top: rem-calc(15);
        }

        li {
            padding-bottom: rem-calc(10);
        }
    }

    &__heading {
        margin: 0;
        color: $blueberry;
    }

    &__button-container {
        display: flex;
        flex-direction: column;
        margin-top: rem-calc(20);
        align-items: center;

        @include breakpoint(small only) {
            width: 100%;
        }

        @include breakpoint(medium) {
            flex-direction: row;
            margin-top: rem-calc(30);
        }

        @include breakpoint(large) {
            margin-top: rem-calc(50);
        }
    }

    &__button {
        @include breakpoint(small only) {
            width: 100%;

            &.clear {
                width: unset;
            }
        }

        @include breakpoint(medium) {
            margin: rem-calc(0 8 0 0);
        }
    }

    &__leading-line-container {
        @include xy-grid-container;
    }

    &__leading-line-inner-container {
        @include xy-grid;
        position: relative;
        min-height: rem-calc(30);
        margin-bottom: rem-calc(-30);

        @include breakpoint(medium) {
            min-height: rem-calc(50);
            margin-bottom: rem-calc(-50);
        }

        @include breakpoint(large) {
            min-height: rem-calc(80);
            margin-bottom: rem-calc(-80);
        }
    }

    &__leading-line {
        position: absolute;
        width: rem-calc(2);
        height: 0;
        background: $duckegg;
        z-index: 4;
        visibility: hidden;
        opacity: 0;
        transition: all 1s ease-in-out;
        top: rem-calc(50);
        left: rem-calc(30);

        @include breakpoint(medium) {
            left: rem-calc(50);
            top: rem-calc(100);
        }

        @include breakpoint(large) {
            top: rem-calc(130);
        }

        @include breakpoint(xlarge) {
            left: rem-calc(80);
        }

        &--show {
            height: 100%;
            visibility: visible;
            opacity: 1;
            max-height: rem-calc(30);

            @include breakpoint(medium) {
                max-height: rem-calc(50);
            }

            @include breakpoint(large) {
                max-height: rem-calc(80);
            }
        }

        &--hide {
            height: 0;
            visibility: hidden;
            opacity: 0;
            max-height: 0;
        }
    }

    &--white-text {
        h1, h2, h3, h4, h5, h6, p, li {
            color: $white;
        }

        a {
            color: $mint-tint-30;
            text-decoration: underline;

            &:hover {
                color: $mint;
            }
        }
    }
}