﻿/* ==================
      RISK WARNING
   ==================
*/

.risk-warning {
    background-color: $spinach;
    padding-top: rem-calc(15);
    padding-bottom: rem-calc(15);
    z-index: 1000;
    right: 0;
    left: 0;

    &.float {
        position: fixed;
    }

    &.animate-float {
        animation-name: fade-in-from-above;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    &__container {
        @include xy-grid-container;
    }

    &__inner-container {
        @include xy-grid;
    }

    p {
        color: $white;
        margin-bottom: 0;
        @include xy-cell();
        @include h6;

        @include breakpoint(medium) {
            text-align: center;
            @include xy-cell(12);
        }
    }
}

  @keyframes fade-in-from-above {
    from {margin-top: -6.3rem; opacity: 0.1;}
    to {margin-top: 0; opacity: 1;}
  }