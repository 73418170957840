﻿/* ============
      FOOTER
   ============
*/
.footer {
    margin-top: rem-calc(20);
    position: relative;
    z-index: 2;

    @include breakpoint(medium) {
        margin-top: rem-calc(30);
    }

    @include breakpoint(large) {
        margin-top: rem-calc(60);
    }

    &__main-content {
        background-color: $blueberry;
        padding: rem-calc(0 0 30);

        @include breakpoint(large) {
            padding: rem-calc(60) 0;
        }
    }

    &__container {
        @include xy-grid-container;
    }

    &__inner-container {
        @include xy-grid;
        @include xy-cell(12);
        justify-content: flex-end;
    }

    &__details-container {
        @include xy-cell(12);
        padding-top: rem-calc(30);

        @include breakpoint(medium) {
            @include xy-cell(5);
        }

        @include breakpoint(large) {
            @include xy-cell(3);
            padding-top: 0;
        }
    }

    &__links-container {
        @include xy-cell-offset(12);
        @include xy-cell(6);
        padding-top: rem-calc(30);

        @include breakpoint(medium) {
            @include xy-cell(3);
        }

        @include breakpoint(large) {
            @include xy-cell(2);
            padding-top: 0;
        }
    }

    &__links-subheading {
        margin: 0 0 rem-calc(15) 0;
        font-weight: 700 !important;
        color: $white;
        font-size: rem-calc(18);
        display: block;

        @include breakpoint(medium) {
            margin: 0 0 rem-calc(12) 0;
        }

        &--multi {
            margin-top: rem-calc(30);
        }
    }

    &__link {
        display: flex;
        align-items: center;
        color: $white;
        padding: rem-calc(6) 0;
        font-size: rem-calc(16);

        @include breakpoint(medium) {
            padding: rem-calc(8) 0;
        }

        &:focus {
            outline: none;
            font-weight: 700;
            color: $duckegg-tint-50;
        }
    }

    &__links-list-item {

        &--hide {
            pointer-events: none;
            opacity: 0;
            margin-top: -3.7rem;
        }
    }

    &__badge {
        margin-left: rem-calc(8);
    }

    &__ph-number-and-main {
    }

    &__ph-number {
        @include h3;
        color: $white;
    }

    &__downing-main {
        margin-top: rem-calc(5);

        @include breakpoint(large) {
            margin-top: rem-calc(20);
        }
    }

    &__downing-main-heading {
        color: $white;
        @include h6;

        &-link {
            font-weight: 700;
        }
    }

    &__downing-main-addition {
        color: $blueberry-tint-50;
        font-size: rem-calc(14);
    }

    &__disclaimer {
        color: $blueberry;
        font-size: rem-calc(14);
        padding: rem-calc(15) 0;
        @include xy-cell();

        @include breakpoint(medium) {
            padding: rem-calc(30) 0;
        }

        @include breakpoint(large) {
            padding: rem-calc(50) 0;
            @include xy-cell(10);
        }
    }

    &__ukcfa {
        @include xy-cell();
        margin-bottom: rem-calc(30);

        @include breakpoint(large) {
            margin-top: rem-calc(50);
            @include xy-cell(2);
        }
    }
}
