﻿/* ===============================
      SCANNABLE CARD COLLECTION
   ===============================
    -   Max-width setting stops cells in bottom row spreading out if it does not
        have the full amount of cells from the rows above
*/

.scannable-card-collection {
    &__container {
        @include xy-grid-container;
    }

    &__inner-container {
        @include xy-grid;
    }

    .card {
        @include xy-cell();

        @include breakpoint(medium) {
            @include xy-cell(4);
            max-width: calc(33.33333% - 1.875rem);
        }

        @include breakpoint(xlarge) {
            @include xy-cell(3);
            max-width: calc(25% - 1.875rem);
        }
    }

    &--colour {
        .card {
            background-color: $spinach;

            &__hover-button-container {
                background-color: $spinach-opacity-60;
            }

            &__text-container {
                background-color: $spinach;
            }

            &__heading {
                color: $white;
            }

            &__text {
                color: $white;
            }

            &__detail {
                color: $white;

                &--arrow {
                    color: $duckegg;
                }
            }
        }
    }
}