﻿/* =============
      FILTER
   =============
*/

.filter {
    @include xy-grid-container;

    &__container {
        @include xy-grid;
    }

    &__inner-container {
        @include xy-cell(12);
        display: flex;
        justify-content: flex-end;
        position: relative;
        /* Hide label */
        label {
            display: none;
        }
        /* Use custom arrow */
        select {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
        }

        &:after {
            position: absolute;
            top: rem-calc(16);
            right: rem-calc(8);
            /* Styling the down arrow */
            width: 0;
            height: 0;
            padding: 0;
            content: '';
            border-left: rem-calc(7) solid transparent;
            border-right: rem-calc(7) solid transparent;
            border-top: rem-calc(8) solid $duckegg;
            pointer-events: none;
        }
    }

    &__label {
        color: $duckegg;
        font-size: rem-calc(18);
        margin-right: rem-calc(15);
        font-weight: 700;
    }

    &__select {
        background-color: transparent;
        padding: rem-calc(5 26 5 5);
        color: $duckegg;
        border: none;
        border-radius: 0;
        font-weight: 700;
        text-transform: uppercase;
        font-size: rem-calc(16);
        outline: none;
        min-width: rem-calc(180);
        max-width: rem-calc(400);
        width: auto;
        margin-bottom: 0;
        border-bottom: rem-calc(1) solid $duckegg;

        option {
            text-transform: none;
            background-color: $white;
            color: $blueberry;

            &:disabled {
                background-color: $grey-tint-15;
                color: $grey;
            }
        }

        @include breakpoint(large) {
            min-width: rem-calc(200);
            font-size: rem-calc(18);
        }

        &:focus {
            border-color: transparent;
            box-shadow: none;
            background-color: transparent;
            outline: 0;
            border-bottom: rem-calc(2) solid $duckegg;
        }
    }
}