﻿/* ===========
      TILE
   ===========
*/
@import '../feijoa-variables';

.tile {
    box-shadow: $initial-shadow;
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
    @include xy-cell(6);
    min-width: rem-calc(120);
    margin-bottom: rem-calc(20);

    @include breakpoint(medium) {
        @include xy-cell(3);
        margin-bottom: rem-calc(30);
        min-width: calc(25% - 1.875rem);
    }

    &:hover {
        box-shadow: $hover-shadow;
        top: rem-calc(-2);

        .tile__background-img {
            background-size: 105% auto;
        }

        .tile__arrow {
            animation: wiggle 1.8s infinite;
        }
    }

    &:focus {
        outline: none;
    }

    &__background-img {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 100%;
        width: 100%;
        transition: all 0.3s ease-in-out;
    }

    &__overlay {
        background-color: $duckegg-opacity-80;
        height: 100%;
        width: 100%;
        padding: rem-calc(15);
        transition: all 0.3s ease-in-out;

        @include breakpoint(large) {
            padding: rem-calc(20);
        }
    }

    &__heading {
        color: $white;
        min-height: rem-calc(80);
        font-family: "Lato", sans-serif;

        @include breakpoint(small only) {
            font-size: rem-calc(16);
        }

        @include breakpoint(medium) {
            margin: 0 0 rem-calc(40) 0;
        }

        @include breakpoint(large) {
            width: 80%;
            min-height: rem-calc(135);
        }

        @include breakpoint(xlarge) {
            min-height: rem-calc(179);
        }

        @include breakpoint(xxlarge) {
            min-height: rem-calc(183);
        }
    }

    &__arrow {
        color: $white;
        position: absolute;
        bottom: rem-calc(15);
        right: rem-calc(15);
        font-size: rem-calc(26) !important;

        @include breakpoint(large) {
            font-size: rem-calc(35) !important;
        }
    }

    &--img-dom {
        box-shadow: none;

        .tile__background-img {
            height: rem-calc(160);
            position: relative;

            @include breakpoint(medium) {
                height: rem-calc(180);
            }

            @include breakpoint(large) {
                height: rem-calc(262);
            }
        }

        .tile__outer-heading, .tile__outer-detail {
            color: $blueberry;
            font-size: rem-calc(12);

            @include breakpoint(medium) {
                font-size: rem-calc(14);
            }

            @include breakpoint(large) {
                font-size: rem-calc(18);
            }
        }

        .tile__outer-heading {
            font-weight: 700;
            margin-top: rem-calc(12);
            margin-bottom: rem-calc(0);

            @include breakpoint(medium) {
                margin-top: rem-calc(15);
            }
        }

        .tile__outer-detail {
            margin-bottom: rem-calc(0);
        }

        .tile__overlay {
            background-color: $spinach-opacity-15;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .tile__hover-button {
            opacity: 0;
            margin: 0;
            transition: opacity 0.3s ease-in-out 0.2s;
        }

        .tile__arrow {
            opacity: 0;
        }

        &:hover {
            box-shadow: none;
            top: unset;

            .tile__background-img {
                background-size: cover;
            }

            .tile__hover-button {
                @include breakpoint(large) {
                    opacity: 1;
                }
            }

            .tile__arrow {
                @include breakpoint(medium down) {
                    opacity: 1;
                }
            }

            .tile__overlay {
                background-color: $duckegg-opacity-80;
            }
        }

        &:focus:active {
            .tile__outer-heading, .tile__outer-detail {
                @include breakpoint(medium down) {
                    color: $mint;
                }
            }

            .tile__overlay {
                opacity: 1;
            }
        }
    }
}