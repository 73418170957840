﻿/* =================
      OUR OFFICES
   =================
*/

.our-offices {
    z-index: 2;
    position: relative;
    background-color: $spinach-opacity-80;
    height: rem-calc(360);
    top: rem-calc(360);
    margin-top: rem-calc(-330);


    @include breakpoint(medium) {
        height: rem-calc(400);
        top: rem-calc(400);
        margin-top: rem-calc(-340);
    }

    @include breakpoint(large) {
        height: rem-calc(600);
        top: rem-calc(600);
        margin-top: rem-calc(-500);
    }

    &__container {
        @include xy-grid-container;
        padding-top: rem-calc(30);


        @include breakpoint(medium) {
            padding-top: rem-calc(80);
        }

        @include breakpoint(large) {
            padding-top: rem-calc(160);
        }
    }

    &__inner-container {
        @include xy-grid;
    }

    &__heading {
        @include xy-cell();
        font-family: "Prata", serif;
        color: $white;
        margin-bottom: 0;

        @include breakpoint(medium) {
            @include xy-cell(4);
        }
    }

    &__office {
        @include xy-cell();
        color: $white;
        margin-top: rem-calc(15);

        @include breakpoint(medium) {
            @include xy-cell(4);
            margin-top: rem-calc(0);
        }
    }

    &__office-label {
        font-weight: 700;
        margin-bottom: rem-calc(2);

        @include breakpoint(medium) {
            margin-bottom: rem-calc(8);
        }
    }

    &__office-address {
        margin-bottom: 0;
    }

    &__button-container {
        margin-top: rem-calc(20);
        @include xy-cell();

        @include breakpoint(medium) {
            margin-top: rem-calc(35);
            @include xy-cell(8);
            @include xy-cell-offset(4);
            padding-left: rem-calc(5);
        }

        @include breakpoint(large) {
            margin-top: rem-calc(60);
        }
    }

    &__button {
        margin-bottom: 0;

        @include breakpoint(small only) {
            width: 100%;
        }
    }
}

#map {
    width: 100%;
    height: rem-calc(360);

    @include breakpoint(medium) {
        height: rem-calc(400);
    }

    @include breakpoint(large) {
        height: rem-calc(600);
    }
}