﻿/* =========================
      POSTCARD COLLECTION
   =========================
*/

.postcard-collection {
    padding: rem-calc(20 0);

    @include breakpoint(medium) {
        padding: rem-calc(70 0);
    }

    &__container {
        @include xy-grid-container;
    }

    &__inner-container {
        @include xy-grid;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            left: 8.3333%;
            border-left: rem-calc(2) solid $duckegg;
            top: rem-calc(-30);
            bottom: rem-calc(-40);

            @include breakpoint(medium) {
                left: 15%;
                top: rem-calc(-90);
                bottom: rem-calc(-110);
            }

            @include breakpoint(large) {
                left: 8.3333%;
            }
        }
    }
}