﻿/* ==========
      HERO
   ==========
*/

.hero {
    background-color: $blueberry;
    background-position: center;
    background-size: cover;
    transition: all 0.3s ease-in-out;

    &__overlay {
        background-color: $blueberry-opacity-60;
        position: relative;
    }

    &__container {
        @include xy-grid-container;
    }

    &__inner-container {
        @include xy-grid;
        position: relative;
    }

    &__structure {
        position: absolute;
        display: none;
        z-index: 3;

        &--top {
            height: rem-calc(120);
            top: rem-calc(110);
            left: rem-calc(-40);

            @include breakpoint(large) {
                display: block;
                height: rem-calc(190);
                left: rem-calc(15);
                top: rem-calc(85);
            }

            @include breakpoint(xxlarge) {
                left: rem-calc(-90);
                top: rem-calc(90);
            }
        }

        &--bottom {
            height: rem-calc(200);
            bottom: rem-calc(-40);
            right: rem-calc(15);

            @include breakpoint(medium) {
                display: block;
            }

            @include breakpoint(large) {
                height: rem-calc(300);
                bottom: rem-calc(-80);
            }
        }
    }

    &__heading {
        color: $white;
        margin: 0;
        padding-top: rem-calc(30);
        padding-bottom: 0;
        @include xy-cell();
        position: relative;
        left: rem-calc(-150);
        animation: slide 1s forwards;

        @keyframes slide {
            0% {
                opacity: 0;
            }

            93% {
                left: rem-calc(3);
            }

            97% {
                left: 0;
            }

            100% {
                opacity: 1;
                left: 0;
            }
        }

        @include breakpoint(medium) {
            padding-top: rem-calc(100);
            @include xy-cell(10);
        }

        @include breakpoint(large) {
            padding-top: rem-calc(160);
            @include xy-cell-offset(1);
        }

        @include breakpoint(xxlarge) {
            @include xy-cell-offset(0);
        }


        &--nothing-under {
            padding-bottom: rem-calc(60);

            @include breakpoint(medium) {
                padding-bottom: rem-calc(100);
            }

            @include breakpoint(large) {
                padding-bottom: rem-calc(160);
            }
        }
    }

    &__subheading {
        color: $white;
        @include xy-cell();
        margin-bottom: 0;

        @include breakpoint(medium) {
            @include xy-cell(10);
            padding-top: rem-calc(10);
        }

        &--with-leading-line {
            padding-bottom: rem-calc(30);

            @include breakpoint(medium) {
                padding-bottom: rem-calc(50);
            }

            @include breakpoint(large) {
                padding-bottom: rem-calc(100);
                @include xy-cell-offset(1);
            }

            @include breakpoint(xxlarge) {
                @include xy-cell-offset(0);
            }
        }
    }

    &__button-container {
        display: flex;
        flex-direction: column;
        margin-top: rem-calc(20);
        margin-bottom: rem-calc(20);
        align-items: center;
        @include xy-cell();

        @include breakpoint(medium) {
            flex-direction: row;
            margin-top: rem-calc(30);
            margin-bottom: rem-calc(60);
            @include xy-cell(10);
        }

        @include breakpoint(large) {
            margin-top: rem-calc(40);
            margin-bottom: rem-calc(100);
            @include xy-cell-offset(1);
        }

        @include breakpoint(xxlarge) {
            @include xy-cell-offset(0);
        }
    }

    &__button {
        @include breakpoint(small only) {
            width: 100%;

            &.clear {
                width: unset;
            }
        }

        @include breakpoint(medium) {
            margin-right: rem-calc(8);
            margin-bottom: 0;
        }
    }

    &__leading-line {
        position: absolute;
        width: rem-calc(2);
        height: 0;
        background: $duckegg;
        z-index: 4;
        visibility: hidden;
        opacity: 0;
        transition: all 1s ease-in-out;
        top: 88%;
        left: rem-calc(30);

        @include breakpoint(medium) {
            left: rem-calc(50);
            top: 86%;
        }

        @include breakpoint(large) {
            top: 76%;
        }

        @include breakpoint(xlarge) {
            top: 83%;
            left: rem-calc(140);
        }

        @include breakpoint(xxlarge) {
            left: rem-calc(100);
        }

        &--show {
            height: 100%;
            visibility: visible;
            opacity: 1;
            max-height: rem-calc(50);

            @include breakpoint(medium) {
                max-height: rem-calc(80);
            }

            @include breakpoint(large) {
                max-height: rem-calc(140);
            }

            @include breakpoint(xlarge) {
                max-height: rem-calc(160);
            }
        }

        &--hide {
            height: 0;
            visibility: hidden;
            opacity: 0;
            max-height: 0;
        }
    }

    &--light {
        background-color: $white;

        .hero__overlay {
            background-color: transparent;
        }

        .hero__heading {
            color: $blueberry;
            padding-top: rem-calc(30);
            @include xy-cell-offset(0);

            &--nothing-under {
                padding-bottom: rem-calc(20);

                @include breakpoint(medium) {
                    padding-bottom: rem-calc(30);
                }

                @include breakpoint(large) {
                    padding-bottom: rem-calc(60);
                }

                @include breakpoint(xlarge) {
                    padding-bottom: rem-calc(80);
                }
            }
        }

        .hero__subheading {
            color: $blueberry;
            @include xy-cell-offset(0);
        }

        .hero__structure {
            &--complex {
                height: rem-calc(200);
                top: rem-calc(-140);
                right: rem-calc(20);

                @include breakpoint(medium) {
                    display: block;
                }

                @include breakpoint(large) {
                    height: rem-calc(280);
                    top: rem-calc(-160);
                    right: rem-calc(50);
                }
            }
        }
    }

    &--dark {
        background-color: $spinach;

        .hero__overlay {
            background-color: transparent;
        }

        .hero__heading {
            padding-top: rem-calc(30);
            @include xy-cell-offset(0);

            &--nothing-under {
                padding-bottom: rem-calc(20);

                @include breakpoint(medium) {
                    padding-bottom: rem-calc(30);
                }

                @include breakpoint(large) {
                    padding-bottom: rem-calc(80);
                }
            }
        }

        .hero__subheading {
            @include xy-cell-offset(0);
        }

        .hero__structure {
            &--complex {
                height: rem-calc(200);
                top: rem-calc(-140);
                right: rem-calc(20);

                @include breakpoint(medium) {
                    display: block;
                }

                @include breakpoint(large) {
                    height: rem-calc(280);
                    top: rem-calc(-160);
                    right: rem-calc(50);
                }
            }
        }
    }
}