﻿/* ======================
      FEIJOA VARIABLES
   ======================
   Design system colours
*/


/* COLOURS
   ------------------------------------ */

// Primary
$duckegg:               #0BB5B5;
$duckegg-tint-70:       #54CBCB;
$duckegg-tint-50:       #85DADA;
$duckegg-opacity-20:    rgba(11, 181, 181, 0.2);
$duckegg-opacity-80:    rgba(11, 181, 181, 0.8);
$blueberry:             #224666;
$blueberry-tint-70:     #647D93;
$blueberry-opacity-80:  rgba(34, 70, 102, 0.8);
$blueberry-opacity-60:  rgba(34, 70, 102, 0.6);
$blueberry-tint-50:     #90A2B2;
$blueberry-opacity-40:  rgba(34, 70, 102, 0.4);
$blueberry-opacity-30:  rgba(34, 70, 102, 0.3);
$blueberry-opacity-20:  rgba(34, 70, 102, 0.2);
$blueberry-tint-30:     #BCC7D1;
$spinach:               #2C7573;
$spinach-opacity-80:    rgba(44, 117, 115, 0.8);
$spinach-opacity-60:    rgba(44, 117, 115, 0.6);
$spinach-opacity-30:    rgba(44, 117, 115, 0.3);
$spinach-opacity-15:    rgba(44, 117, 115, 0.15);
$spinach-tint-75:       #619896;
$spinach-tint-50:       #95BAB9;
$spinach-tint-25:       #CADCDC;
$spinach-tint-13:       #E5EEEE;

// Secondary
$mint:                  #00D685;
$mint-tint-70:          #4CE2A9;
$mint-tint-50:          #7FEAC2;
$mint-tint-30:          #B2F2DA;
$mint-opacity-20:       rgba(0, 214, 133, 0.2);
$mint-opacity-80:       rgba(0, 214, 133, 0.8);
$feijoa:                #3DAA01;
$feijoa-tint-70:        #77C34D;
$feijoa-tint-50:        #9ED480;
$feijoa-tint-20:        #3daa0133;
$feijoa-opacity-20:     rgba(61, 170, 1, 0.2);

// Tertiary
$oreo:                  #0088CE;
$mentos:                #00B9FF;
$raspberry:             #A01663;
$mandarin:              #E98E0F;
$grape:                 #54429B;
$cucumber:              #008542;
$watermelon:            #EB4D85;


// Validation/messaging
$danger:                #F7615B;
$danger-tint-70:        #F9908C;
$danger-tint-20:        #FDDFDE;
$danger-tint-30:        #f7615b4d;
$danger-opacity-20:     rgba(247, 97, 91, 0.2);


// Basics
$white:                 #FFFFFF;
$white-opacity-40:      rgba(255, 255, 255, 0.4);
$white-opacity-60:      rgba(255, 255, 255, 0.6);
$white-opacity-80:      rgba(255, 255, 255, 0.8);
$black:                 #252C26;
$grey:                  #565A5C;
$grey-tint-75:          #808385;
$grey-tint-50:          #AAACAD;
$grey-tint-25:          #D5D6D6;
$grey-tint-15:          #E6E7E7;
$grey-opacity-20:       rgba(86, 90, 92, 0.2);

/* SHADOWS
   ------------------------------------ */
$initial-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
$hover-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);

/* ANIMATIONS
   ------------------------------------ */

//Used for arrow hover states on cards and tiles
@keyframes wiggle {
    0% {
        right: rem-calc(15);
    }

    50% {
        right: rem-calc(8);
    }

    100% {
        right: rem-calc(15);
    }
}
