﻿/* ==============
      SHOWCASE
   ==============
*/

.showcase {
    background: $spinach-tint-25;
    height: rem-calc(200);
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
    z-index: 2;
    background-size: cover;
    //ios doesn't support background-size: cover (results in no parallax effect)
    @supports (-webkit-overflow-scrolling: touch) {
        background-size: 100%;
        background-attachment: scroll;
    }

    @include breakpoint(medium) {
        height: rem-calc(250);
    }

    @include breakpoint(large) {
        height: rem-calc(380);
    }

    &__leading-line {
        height: rem-calc(200);
        background: $mentos;
        position: absolute;
        width: 100%;

        @include breakpoint(medium) {
            height: rem-calc(250);
        }

        @include breakpoint(large) {
            height: rem-calc(380);
        }
    }

    &__leading-line-container {
        @include xy-grid-container;
        height: 100%;
    }

    &__leading-line-inner-container {
        @include xy-grid;
        height: 100%;
        position: relative;

        &:after {
            content: "";
            position: relative;
            left: 8.3333%;
            border-left: rem-calc(2) solid $duckegg;
            top: rem-calc(-15);
            bottom: rem-calc(-40);
            transition: opacity 1.5s ease-in-out;
            transition: max-height 2s ease-in-out;

            @include breakpoint(medium) {
                left: 15%;
                top: rem-calc(-50);
                bottom: rem-calc(-110);
            }

            @include breakpoint(large) {
                left: 8.3333%;
                top: rem-calc(-85);
            }
        }

        &--show {
            &:after {
                visibility: visible;
                opacity: 1;
                height: calc(100% + 30px);
                max-height: calc(100% + 30px);

                @include breakpoint(medium) {
                    height: calc(100% + 100px);
                    max-height: calc(100% + 100px);
                }

                @include breakpoint(large) {
                    height: calc(100% + 170px);
                    max-height: calc(100% + 170px);
                }
            }
        }

        &--hide {
            &:after {
                height: 0;
                visibility: hidden;
                opacity: 0;
                max-height: 0;
            }
        }
    }

    &__container {
        @include xy-grid-container;
        height: 100%;
    }

    &__inner-container {
        @include xy-grid;
        height: 100%;
        position: relative;
    }

    &__caption {
        //Higher opacity because the background image doesn't have solid colour overlay
        background-color: $white-opacity-60;
    }
}