﻿/* ==============
      SNACKBAR
   ==============
*/

.snackbar {
    box-shadow: $hover-shadow;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 999999;
    background-color: $black;
    color: $white;
    padding: rem-calc(15 20);
    opacity: 0.95;

    @include breakpoint(medium) {
        min-width: rem-calc(300);
        max-width: rem-calc(420);
        border-radius: rem-calc(3);
        padding: rem-calc(15);
    }

    &__msg {
        font-size: rem-calc(14);
    }

    &__button {
        margin: 0 0 0 rem-calc(10);
        font-size: rem-calc(14);
        padding: rem-calc(2 5) !important;

        @include breakpoint(medium) {
            margin: 0 0 0 rem-calc(15);
        }
    }
}