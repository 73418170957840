﻿/* ==============
      POSTCARD
   ==============
*/

$postcardHeight: 452;
$largePostcardHeight: 580;
$largePostcardHeightTablet: 482;


.postcard {
    background-color: $white;
    @include xy-cell(12, 1);
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;
    margin-bottom: rem-calc(20);
    z-index: 2;

    @include breakpoint(medium) {
        @include xy-cell(10, 1);
        margin-bottom: rem-calc(30);
    }

    @include breakpoint(medium only) {
        margin-left: auto;
        margin-right: auto;
    }

    @include breakpoint(large) {
        height: rem-calc($postcardHeight);
        @include xy-cell(12, 1);
        justify-content: space-between;
        flex-direction: row;
    }

    @include breakpoint(xlarge) {
        @include xy-cell(11, 1);
    }

    &--thin {
        @include breakpoint(large) {
            max-height: rem-calc(320);
            min-height: rem-calc(320);
        }
    }

    &__overlay {
        background-color: $spinach-opacity-30;
        width: 100%;
        height: 100%;
    }

    &__img {
        background-color: $grey-tint-15;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0;
        width: 100%;
        height: rem-calc(160);
        @include xy-cell(12, 1, 0);

        @include breakpoint(medium) {
            height: rem-calc(300);
        }

        @include breakpoint(large) {
            @include xy-cell(4);
            margin-right: 0;
            position: absolute;
            right: 0;
            height: 100%;
            max-height: unset;
            min-height: unset;
        }
    }

    &__main-content {
        padding: rem-calc(20);
        margin: 0;
        width: 100%;

        @include breakpoint(medium) {
            padding: rem-calc(40);
        }

        @include breakpoint(large) {
            @include xy-cell(8);
            height: rem-calc($postcardHeight);
            padding: rem-calc(50 50 50 40);
            display: flex;
            flex-direction: column;
        }
    }

    &__heading {
        color: $blueberry;
        margin: 0;
        flex: 0 0 auto;
    }

    &__content-block {
        color: $blueberry;
        font-size: rem-calc(14);
        margin: rem-calc(15 0 0);

        @include breakpoint(medium) {
            font-size: rem-calc(18);
            margin: rem-calc(30 0 0);
        }

        @include breakpoint(large) {
            flex: 1;
        }

        p {
            margin-bottom: rem-calc(8);
        }

        ul {
            margin-bottom: 0;
        }

        li {
            padding-bottom: rem-calc(4);

            &:last-of-type {
                padding-bottom: 0;
            }
        }

        &:last-of-type {
            padding-bottom: rem-calc(4);
        }
    }

    &__button-container {
        display: flex;
        justify-content: flex-end;

        @include breakpoint(medium) {
            justify-content: flex-start;
        }

        @include breakpoint(large) {
            flex: 0 0 auto;
        }

        & .button {
            margin: rem-calc(15 0 0 0);
            width: 100%;

            &.clear {
                width: auto;
            }

            @include breakpoint(medium) {
                margin: rem-calc(50 0 0 0);
                width: auto;
            }
        }
    }
}

.postcard--large {
    flex-direction: column;

    @include breakpoint(xlarge) {
        @include xy-cell(12, 1);
    }

    @include breakpoint(large) {
        min-height: rem-calc($largePostcardHeight);
    }

    @include breakpoint(medium) {
        @include xy-cell(12, 1);
    }

    &__img {
        min-height: rem-calc($largePostcardHeight);
    }

    & .postcard__img {
        max-height: rem-calc($largePostcardHeightTablet);
        overflow: hidden;
        @include xy-cell(5, 1, 0);
        margin-right: 0;

        @include breakpoint(medium) {
            position: absolute;
            right: 0;
            height: 100%;
            max-height: unset;
            min-height: unset;
            display: block;
            @include xy-cell(5, 1, 0);
        }

        @include breakpoint(small) {
            display: none;
            @include xy-cell(0, 0, 0);
        }
    }


    & .postcard__main-content {
        min-height: rem-calc($largePostcardHeightTablet);
        display: flex;
        flex-direction: column;
        padding: rem-calc(40, 28);
        flex: 1;
        @include xy-cell(7, 1);

        @include breakpoint(large) {
            // min-height: rem-calc($largePostcardHeight);
        }

        @include breakpoint(medium) {
            @include xy-cell(7, 1);
            padding: rem-calc(40);
        }

        @include breakpoint(small) {
            @include xy-cell(12, 1, 0);
        }
    }

    & .postcard__heading {
        flex: 0 0 auto;
    }

    & .postcard__content-block {
        flex: 1;

        @include breakpoint(small) {
            @include xy-cell(12, 1, 0);
        }
    }

    & .postcard__button-container {
        flex: 0 0 auto;

        & .button {
            margin: 3rem 0 1.6rem 0;

            @include breakpoint(large) {
                @include button-large;
            }
        }
    }
}