﻿/* ====================================
      SCANNABLE LOGO LINK COLLECTION
   ====================================
*/

.scannable-logo-link-collection {
    &__container {
        @include xy-grid-container;
    }

    &__inner-container {
        @include xy-grid;
    }
}