/* ===================
      BASE CSS FILE
   ===================
   Import SASS files into this file
*/

/* FEIJOA VARIABLES
    ------------------------------------ */

@import './feijoa-variables';

/* FOUNDATION
    ------------------------------------ */

@import '../../../node_modules/foundation-sites/scss/foundation.scss';

/* FOUNDATION SETTINGS
    ------------------------------------ */

@import './settings';

/* FOUNDATION INCLUDE OPTIONS
   ------------------------------------ */

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grid
@include foundation-xy-grid-classes;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

/* ANGULAR MATERIAL
    ------------------------------------ */

// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// @import '@angular/material/'
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Custom palettes. They were generated by going to http://mcg.mbitson.com/
$mat-downing-duckegg: ( 50 : #e2f6f6, 100 : #b6e9e9, 200 : #85dada, 300 : #54cbcb, 400 : #30c0c0, 500 : #0bb5b5, 600 : #0aaeae, 700 : #08a5a5, 800 : #069d9d, 900 : #038d8d, A100 : #b9ffff, A200 : #86ffff, A400 : #53ffff, A700 : #3affff, contrast: ( 50 : #000000, 100 : #000000, 200 : #000000, 300 : #000000, 400 : #000000, 500 : #000000, 600 : #ffffff, 700 : #ffffff, 800 : #ffffff, 900 : #ffffff, A100 : #000000, A200 : #000000, A400 : #000000, A700 : #000000, ) );

$mat-downing-blueberry: ( 50 : #e4e9ed, 100 : #bdc8d1, 200 : #91a3b3, 300 : #647e94, 400 : #43627d, 500 : #224666, 600 : #1e3f5e, 700 : #193753, 800 : #142f49, 900 : #0c2038, A100 : #72adff, A200 : #3f8fff, A400 : #0c71ff, A700 : #0065f1, contrast: ( 50 : #000000, 100 : #000000, 200 : #000000, 300 : #ffffff, 400 : #ffffff, 500 : #ffffff, 600 : #ffffff, 700 : #ffffff, 800 : #ffffff, 900 : #ffffff, A100 : #000000, A200 : #000000, A400 : #ffffff, A700 : #ffffff, ) );

$mat-downing-spinach: ( 50 : #e6eeee, 100 : #c0d6d5, 200 : #96bab9, 300 : #6b9e9d, 400 : #4c8a88, 500 : #2c7573, 600 : #276d6b, 700 : #216260, 800 : #1b5856, 900 : #104543, A100 : #80fff9, A200 : #4dfff7, A400 : #1afff5, A700 : #00fff4, contrast: ( 50 : #000000, 100 : #000000, 200 : #000000, 300 : #000000, 400 : #ffffff, 500 : #ffffff, 600 : #ffffff, 700 : #ffffff, 800 : #ffffff, 900 : #ffffff, A100 : #000000, A200 : #000000, A400 : #000000, A700 : #000000, ) );

$mat-downing-mint: ( 50 : #e0faf0, 100 : #b3f3da, 200 : #80ebc2, 300 : #4de2aa, 400 : #26dc97, 500 : #00d685, 600 : #00d17d, 700 : #00cc72, 800 : #00c668, 900 : #00bc55, A100 : #e5ffef, A200 : #b2ffcf, A400 : #7fffb0, A700 : #65ffa0, contrast: ( 50 : #000000, 100 : #000000, 200 : #000000, 300 : #000000, 400 : #000000, 500 : #000000, 600 : #000000, 700 : #000000, 800 : #000000, 900 : #ffffff, A100 : #000000, A200 : #000000, A400 : #000000, A700 : #000000, ) );

$mat-downing-danger: ( 50 : #feeceb, 100 : #fdd0ce, 200 : #fbb0ad, 300 : #f9908c, 400 : #f87974, 500 : #f7615b, 600 : #f65953, 700 : #f54f49, 800 : #f34540, 900 : #f1332f, A100 : #ffffff, A200 : #ffffff, A400 : #ffcecd, A700 : #ffb5b3, contrast: ( 50 : #000000, 100 : #000000, 200 : #000000, 300 : #000000, 400 : #000000, 500 : #000000, 600 : #000000, 700 : #ffffff, 800 : #ffffff, 900 : #ffffff, A100 : #000000, A200 : #000000, A400 : #000000, A700 : #000000, ) );

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Downing-BOP-Portal-primary: mat-palette($mat-downing-duckegg);
$Downing-BOP-Portal-accent: mat-palette($mat-downing-blueberry); //, A200, A100, A400);

// The warn palette is optional (defaults to red).
$Downing-BOP-Portal-warn: mat-palette($mat-downing-danger);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$Downing-BOP-Portal-theme: mat-light-theme(( color: ( primary: $Downing-BOP-Portal-primary, accent: $Downing-BOP-Portal-accent, warn: $Downing-BOP-Portal-warn, ) ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@include angular-material-theme($Downing-BOP-Portal-theme);

.mat-form-field {
  font-family: inherit;
}

.material-icons {
  font-size: inherit;
  line-height: inherit;
  vertical-align: bottom;
}

.mat-expansion-panel-header-title {
  font-family: $body-font-family;
}

.mat-expansion-panel
.mat-expansion-panel-content
{
  font-family: $body-font-family;
}

snack-bar-container {
  &.notification {
    background-color: $blueberry;
  }

  &.danger {
    background-color: $danger;
  }
}

.mat-simple-snackbar {
  font-family: inherit;
  font-size: rem-calc(16);
}

.mat-progress-spinner, .mat-spinner {
  display: inline-block;
  margin-right: rem-calc(8);
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: $white;
}

.mat-header-cell {
  color: $duckegg;
  font-size: rem-calc(16);
  font-weight: 400;
}

.mat-slide-toggle-content {
  font-size: rem-calc(18);
}

.mat-table {
  font-family: $body-font-family;
}

textarea{
  outline: none;
  border-style: none;
  overflow: auto;
  resize: none;
  border: none;
  box-shadow: none;

  &:focus {
    outline: none;
    border-style: none;
    overflow: auto;
    resize: none;
    border: none;
    box-shadow: none;
  }
}

/* GLOBAL SETTINGS
    ------------------------------------ */

@import './feijoa'; // Branding & design system

/* PARTIALS/BLOCKS
   ------------------------------------
    Alphabetical order please
*/

@import './Shared/accordion';
@import './Shared/awards';
@import './Shared/caption';
@import './Shared/closable-callout';
@import './Shared/contained-tile-collection';
@import './Shared/cookie-consent';
@import './Shared/download-button';
@import './Shared/downloads-section';
@import './Shared/dual-column-layout';
@import './Shared/filter';
@import './Shared/footer';
@import './Shared/full-width-text';
@import './Shared/hero';
@import './Shared/hover-grid';
@import './Shared/info-alert';
@import './Shared/info-group';
@import './Shared/info-group-collection';
@import './Shared/logo-link';
@import './Shared/marketing-banner';
@import './Shared/media-object';
@import './Shared/modal';
@import './Shared/off-canvas-menu';
@import './Shared/our-offices';
@import './Shared/options-footer';
@import './Shared/page-subheading';
@import './Shared/postcard';
@import './Shared/postcard-collection';
@import './Shared/profile';
@import './Shared/risk-warning';
@import './Shared/scannable-card-collection';
@import './Shared/scannable-logo-link-collection';
@import './Shared/scannable-tile-collection';
@import './Shared/showcase';
@import './Shared/_sign-post';
@import './Shared/_sign-post-collection';
@import './Shared/snackbar';
@import './Shared/tile';
@import './Shared/offer-card';
@import './Shared/navbar';
@import './Shared/accordion-card';

/* PAGE SCAFFOLDING
   ------------------------------------
    Alphabetical order please
*/

/*@import 'Pages/adviser-more-info';
@import 'Pages/bond-book';
@import 'Pages/bond-sector';
@import 'Pages/bond-updates';
@import 'Pages/home-page';*/

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
body {
  margin: 0;
  min-height: 100vh;
}
