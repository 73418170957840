﻿/* =====================
      DOWNLOAD BUTTON
   =====================
*/

.download-button {
    border: $white solid rem-calc(1);
    color: $white;
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out;
    max-height: rem-calc(62);
    overflow: hidden;

    @include breakpoint(medium) {
        max-height: rem-calc(78);
    }

    &__icon-container {
        background-color: $white;
        color: $duckegg;
        height: rem-calc(60);
        width: rem-calc(60);
        min-width: rem-calc(60);
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint(medium) {
            height: rem-calc(76);
            width: rem-calc(76);
            min-width: rem-calc(76);
        }
    }

    &__icon {
        font-size: rem-calc(32) !important;
    }

    &__text {
        font-size: rem-calc(18);
        margin: 0 rem-calc(20);
        font-weight: 700;
        line-height: 120%;

        @include breakpoint(medium) {
            margin: 0 rem-calc(30);
        }
    }

    &:hover {
        background-color: $mint;

        .download-button__text {
            color: $white;
        }

        .download-button__icon-container {
            color: $mint;
        }
    }

    &:focus {
        box-shadow: $hover-shadow;
        outline: none;

        .download-button__text {
            color: $white;
        }
    }

    &:active:focus {
        box-shadow: $initial-shadow;
    }


    &--dark {
        border-color: $duckegg;
        background-color: $white;

        .download-button__icon-container {
            background-color: $duckegg;
        }

        .download-button__icon {
            color: $white;
        }

        .download-button__text {
            color: $duckegg;
        }

        &:focus {
            .download-button__text {
                color: $duckegg;
            }
        }

        &:hover {
            border-color: $mint;
            background-color: $white;

            .download-button__icon-container {
                background-color: $mint;
            }

            .download-button__text {
                color: $mint;
            }
        }

        &:active:focus {
            border-color: $mint;
            background-color: $white;
            box-shadow: none;

            .download-button__icon-container {
                background-color: $mint;
            }

            .download-button__text {
                color: $mint;
            }
        }
    }
}