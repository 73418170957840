﻿.off-canvas-menu {
    background-color: $spinach-tint-13;
    padding: rem-calc(35 80);

    @include breakpoint(small only) {
        padding: rem-calc(20);
        width: 100%;
    }

    &__menu-container {
        display: flex;
        flex-direction: column;
    }

    &__menu-group {
        margin-top: rem-calc(30);

        @include breakpoint(medium) {
            margin-top: rem-calc(50);
        }
    }

    &__link-heading {
        color: $blueberry;
        margin-bottom: rem-calc(10);

        @include breakpoint(medium) {
            margin-bottom: rem-calc(20);
        }
    }

    &__link {
        @include h5;
        padding: rem-calc(10 0) !important;
        display: flex;
        align-items: center;
        line-height: 1.2 !important;

        @include breakpoint(medium) {
            padding: rem-calc(10 0) !important;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &__icon {
        position: relative;
        top: rem-calc(3);

        @include breakpoint(small only) {
            font-size: rem-calc(20) !important;
        }
    }

    &__button-container {
        @include breakpoint(medium) {
            display: flex;
            justify-content: flex-end;
        }
    }

    &__close-button {
        .ghost-button__icon {
            font-size: rem-calc(28);
        }

        @include breakpoint(small only) {
            padding: 0;
        }
    }

    &__back-close {
        display: flex;
        align-items: center;

        @include breakpoint(small only) {
            margin: 0;
        }

        &-icon {
            position: relative;
            top: rem-calc(1);
            font-size: rem-calc(28) !important;
            margin: rem-calc(0 5 0 -15);
        }
    }
}
