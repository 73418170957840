﻿/* ==================
      MEDIA OBJECT
   ==================
*/

.media-object {
    margin-bottom: 0;

    &--right-aligned {
        .media-object {
            &__inner-container {
                display: flex;
            }

            &__img-container {
                @include breakpoint(large) {
                    order: 1;
                }
            }

            &__text {
                @include breakpoint(large) {
                    order: 2;
                }
            }
        }
    }

    &__container {
        @include xy-grid-container;
    }

    &__heading {
        @include xy-grid;
        color: $blueberry;
        margin: 2.5rem 0 2rem 0;
    }

    &__inner-container {
        @include xy-grid;
        position: relative;
    }

    &__img-container {
        @include xy-cell();

        @include breakpoint(small only) {
            margin-top: rem-calc(30);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        @include breakpoint(medium only) {
            margin-top: rem-calc(50);
        }

        @include breakpoint(large) {
            @include xy-cell(6);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__img {
        max-width: rem-calc(280) !important;

        @include breakpoint(medium) {
            max-width: rem-calc(590) !important;
        }

        @include breakpoint(large) {
            max-width: rem-calc(460) !important;
        }
    }

    &__text {
        @include xy-cell();
        @include h5;
        color: $blueberry;
        display: flex;
        flex-direction: column;
        margin: 0;

        @include breakpoint(large) {
            @include xy-cell(6);
            margin: 0;
        }

        * {
            margin-bottom: 0;
        }

        h1, h2 {
            margin-bottom: rem-calc(10);

            @include breakpoint(medium) {
                margin-bottom: rem-calc(20);
            }

            @include breakpoint(large) {
                margin-bottom: rem-calc(30);
            }
        }

        h1, h2, h3, h4, h5, h6 {
            margin-top: rem-calc(10);

            @include breakpoint(medium) {
                margin-top: rem-calc(20);
            }

            @include breakpoint(large) {
                margin-top: rem-calc(30);
            }
        }

        p {
            margin-top: rem-calc(10);

            @include breakpoint(medium) {
                margin-top: rem-calc(20);
            }

            @include breakpoint(large) {
                margin-top: rem-calc(30);
            }
        }

        ul, ol {
            margin-top: rem-calc(10);

            @include breakpoint(medium) {
                margin-top: rem-calc(20);
            }

            @include breakpoint(large) {
                margin-top: rem-calc(30);
            }
        }

        ol {
            margin-left: 1.6rem;
        }

        li {
            margin-top: rem-calc(10);

            @include breakpoint(medium) {
                margin-top: rem-calc(20);
            }

            @include breakpoint(large) {
                margin-top: rem-calc(30);
            }
        }

        :first-child {
            margin-top: 0;
        }
    }
}