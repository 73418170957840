﻿/* =============
      PROFILE
   =============
*/

.profile {
    padding: rem-calc(20 0 30);

    @include breakpoint(medium) {
        padding: rem-calc(50 0);
    }

    @include breakpoint(large) {
        padding: rem-calc(100 0);
    }

    &__container {
        @include xy-grid-container;
    }

    &__inner-container {
        @include xy-grid;
        position: relative;
    }

    &__left-col {
        @include xy-cell();

        @include breakpoint(medium) {
            @include xy-cell(5);
        }

        @include breakpoint(large) {
            @include xy-cell(4);
        }
    }

    &__img {
        width: 100%;
    }

    &__right-col {
        @include xy-cell();
        color: $blueberry;

        @include breakpoint(small only) {
            margin-top: rem-calc(20);
        }

        @include breakpoint(medium) {
            @include xy-cell(7);
        }

        @include breakpoint(large) {
            @include xy-cell(8);
        }
    }

    &__content {
        margin-bottom: 0;
    }

    &__button {
        margin: rem-calc(20 0 0);

        @include breakpoint(small only) {
            width: 100%;
        }

        @include breakpoint(medium) {
            margin: rem-calc(30 0 0);
        }

        @include breakpoint(large) {
            margin: rem-calc(50 0 0);
        }
    }
}