﻿/* =====================
      PAGE SUBHEADING
   =====================
*/

.page-subheading {
    padding: rem-calc(40) 0 0 0;

    @include breakpoint(medium) {
        padding: rem-calc(60) 0 0 0;
    }

    @include breakpoint(large) {
        padding: rem-calc(100) 0 0 0;
    }

    &__container {
        @include xy-grid-container;
    }

    &__inner-container {
        @include xy-grid;
        position: relative;
        display: flex;
        align-items: baseline;
        flex-direction: column;

        @include breakpoint(medium) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__text {
        color: $blueberry;
        margin-bottom: 0;
        padding: 0 rem-calc(10);

        @include breakpoint(medium) {
            padding: 0 rem-calc(15);
        }

        &--light { 
            color: $white;
        }
    }

    &__sub-text {
        color: $blueberry;
        margin-bottom: 0;
        padding: rem-calc(5 10 0);

        @include breakpoint(medium) {
            padding: rem-calc(10 15 0);
        }

        @include breakpoint(large) {
            padding: rem-calc(20 15 0);
        }

        p {
            margin-bottom: 0;
        }
    }

    &__link-container {
        @include breakpoint(small only) {
            width: 100%;
            text-align: right;
        }
    }

    &__link {
        margin: rem-calc(10 10) 0;
        padding: rem-calc(2 4) !important;
        text-align: right;

        @include breakpoint(medium) {
            margin: 0 rem-calc(10);
            width: unset;
        }
    }
}