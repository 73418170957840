﻿/* ================
      HOVER GRID
   ================
*/

.hover-grid {
    position: relative;
    z-index: 5;

    &__container {
        @include breakpoint(small only) {
            padding: 0 0 0 rem-calc(20);
        }

        @include breakpoint(medium down) {
            overflow: visible;
        }

        @include breakpoint(medium only) {
            padding: 0 0 0 rem-calc(30);
        }
    }

    &__inner-container {
        position: relative;

        @include breakpoint(large) {
            display: flex;
        }
    }
    //Slick settings
    .slick {
        &-arrow {
            display: none !important;
            opacity: 0;
        }

        &-slide {
            opacity: 1;
        }

        &-track {
            @include breakpoint(large) {
                max-width: 100%;
                max-height: rem-calc(380);
                overflow: hidden;
            }
        }
    }

    &__image {
        height: rem-calc(200);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        @include breakpoint(large) {
            height: rem-calc(380);
        }
    }

    &__overlay {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: all ease-in-out 0.3s;
        background-color: $blueberry-opacity-60;

        @include breakpoint(large) {
            background-color: $blueberry-opacity-30;
        }
    }

    &__logo {
        filter: brightness(0) invert(1);
        transition: all ease-in-out 0.3s;
        max-width: rem-calc(150);

        @include breakpoint(large) {
            opacity: 0;
            max-width: rem-calc(250);
        }

        @include breakpoint(xlarge) {
            max-width: rem-calc(300);
        }

        @include breakpoint(xxlarge) {
            max-width: rem-calc(320);
        }
    }

    &__hover-button {
        margin: rem-calc(30 0 0);
        transition: all ease-in-out 0.3s;
        opacity: 0;
        display: none;

        @include breakpoint(large) {
            display: block;
        }
    }

    &__item {

        @include breakpoint(small only) {
            padding: 0 rem-calc(15) 0 0;
            max-height: rem-calc(200);
            min-height: rem-calc(200) !important;
            max-width: rem-calc(220);
            min-width: rem-calc(220);
            width: rem-calc(220);
        }

        @include breakpoint(medium only) {
            padding: 0 rem-calc(15) 0 0;
            max-height: rem-calc(220);
            min-height: rem-calc(220) !important;
            max-width: rem-calc(300);
            min-width: rem-calc(300);
            width: rem-calc(300);
        }

        @include breakpoint(large) {
            width: 33.333%;
            min-width: 33.333%;
            max-width: 33.333%;
        }

        &:hover {

            .hover-grid__hover-button {
                @include breakpoint(large) {
                    opacity: 1;
                }
            }

            .hover-grid__logo {
                opacity: 1;
            }

            .hover-grid__overlay {
                .hover-grid__overlay {
                    background-color: $duckegg-opacity-80;
                }

                @include breakpoint(large) {
                    background-color: $blueberry-opacity-60;
                }
            }
        }

        &:focus {
            outline: none;

            .hover-grid__hover-button {
                @include breakpoint(large) {
                    opacity: 1;
                    box-shadow: $hover-shadow;
                }
            }

            .hover-grid__logo {
                opacity: 1;
            }

            .hover-grid__overlay {
                background-color: $blueberry-opacity-60;
            }
        }

        &:focus:active {

            .hover-grid__hover-button {
                @include breakpoint(large) {
                    opacity: 1;
                    box-shadow: inset 0 10px 20px $grey-opacity-20, inset 0 6px 6px $grey-opacity-20;
                }
            }

            .hover-grid__logo {
                opacity: 1;
            }

            .hover-grid__overlay {
                background-color: $duckegg-opacity-80;
            }
        }
    }
}