﻿/* ======================
      MARKETING BANNER
   ======================
*/

.marketing-banner {
    &__background {
        overflow: hidden;
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__overlay {
        background-color: $blueberry-opacity-60;
        position: relative;
        height: 100%;

        &--no-image {
            background-color: transparent;
        }
    }

    &__container {
        @include xy-grid-container;
        padding-top: rem-calc(30);
        padding-bottom: rem-calc(30);

        @include breakpoint(medium) {
            padding-top: rem-calc(50);
            padding-bottom: rem-calc(50);
        }

        @include breakpoint(large) {
            padding-top: rem-calc(130);
            padding-bottom: rem-calc(130);
        }
    }

    &__inner-container {
        @include xy-grid;
    }

    &__heading {
        text-align: center;
        z-index: 2;
        @include xy-cell();
        margin-bottom: 0;
        color: $blueberry;

        @include breakpoint(medium) {
            @include xy-cell(10);
            @include xy-cell-offset(1);
        }
    }

    &__button-container {
        display: flex;
        justify-content: center;
        margin: rem-calc(20 0 0);
        width: 100%;
        padding: rem-calc(0 10);

        @include breakpoint(medium) {
            margin: rem-calc(30 0 0);
        }

        @include breakpoint(large) {
            margin: rem-calc(60 0 -30);
        }
    }

    &__button {
        margin: 0;

        @include breakpoint(small only) {
            width: 100%;
        }
    }

    &__structure {
        position: absolute;
        opacity: 0.2;

        @include breakpoint(small only) {
            display: none;
        }

        @include breakpoint(medium) {
            opacity: 0.4;
        }

        &--top {
            height: rem-calc(280);
            top: rem-calc(-150);
            left: rem-calc(0);

            @include breakpoint(medium) {
                height: rem-calc(310);
            }

            @include breakpoint(large) {
                height: rem-calc(450);
                top: rem-calc(-300);
            }
        }

        &--bottom {
            height: rem-calc(250);
            bottom: rem-calc(-100);
            right: rem-calc(0);

            @include breakpoint(medium) {
                bottom: rem-calc(0);
                height: rem-calc(280);
            }

            @include breakpoint(large) {
                height: rem-calc(420);
            }
        }
    }

    &__leading-line-container {
        @include xy-grid-container;
        pointer-events: none;
    }

    &__leading-line-inner-container {
        @include xy-grid;
        pointer-events: none;
        position: relative;
        min-height: rem-calc(30);
        margin-bottom: rem-calc(-30);

        @include breakpoint(medium) {
            min-height: rem-calc(50);
            margin-bottom: rem-calc(-50);
        }

        @include breakpoint(large) {
            min-height: rem-calc(80);
            margin-bottom: rem-calc(-80);
        }
    }

    &__leading-line {
        position: absolute;
        width: rem-calc(2);
        height: 0;
        background: $duckegg;
        z-index: 4;
        visibility: hidden;
        opacity: 0;
        transition: all 1s ease-in-out;
        left: rem-calc(30);

        @include breakpoint(medium) {
            left: rem-calc(50);
        }

        @include breakpoint(xlarge) {
            left: rem-calc(80);
        }

        &--show {
            height: 100%;
            visibility: visible;
            opacity: 1;
            max-height: rem-calc(30);

            @include breakpoint(medium) {
                max-height: rem-calc(50);
            }

            @include breakpoint(large) {
                max-height: rem-calc(80);
            }
        }

        &--hide {
            height: 0;
            visibility: hidden;
            opacity: 0;
            max-height: 0;
        }
    }

    &--white-text {
        .marketing-banner__heading {
            color: $white;
        }
    }
}