@import '../../../node_modules/foundation-sites/scss/foundation';
@import './feijoa-variables';

/* ============
      FEIJOA
   ============
   Design system | Branding assets
*/

/* BUTTONS
   ------------------------------------ */
.button.primary {
  background-color: $duckegg;
  box-shadow: $initial-shadow;

  &:hover {
    background-color: $duckegg-tint-70;
    box-shadow: $hover-shadow;
  }

  &:focus:active {
    background-color: $duckegg-tint-70;
    color: $white;
    box-shadow: inset 0 10px 20px $duckegg, inset 0 6px 6px $duckegg;
  }

  &:disabled {
    background-color: $duckegg;
  }
}

.button.hollow.primary {
    background-color: $white;
    color: $duckegg;
    border-color: $duckegg;
    box-shadow: $initial-shadow;

    &:hover {
        background-color: $duckegg-tint-70;
        box-shadow: $hover-shadow;
        color: $white;
        border-color: $duckegg-tint-70;
    }

    &:focus {
        background-color: $white;
        color: $duckegg;
        border-color: $duckegg;
        box-shadow: $hover-shadow;
        outline: none;
    }

    &:focus:active {
        background-color: $duckegg-tint-70;
        color: $white;
        box-shadow: inset 0 10px 20px $duckegg, inset 0 6px 6px $duckegg;
    }
}

.button.cta {
    background-color: $feijoa;
    box-shadow: $initial-shadow;

    &:hover {
        background-color: $feijoa-tint-70;
        box-shadow: $hover-shadow;
    }

    &:focus:active {
        background-color: $feijoa-tint-70;
        color: $white;
        box-shadow: inset 0 10px 20px $feijoa, inset 0 6px 6px $feijoa;
    }
}

.clear.button {
    text-transform: uppercase;
    font-weight: 400;
    padding: rem-calc(2 4) !important;

    &:hover {
        background-color: $mint-opacity-20 !important;
        color: $mint !important;
    }

    &:focus {
        background-color: $mint-opacity-20;
        color: $mint;
        outline: none;
    }

    &:focus:active {
        background-color: $mint;
        color: $white;
    }
}

.clear.button.white {
    color: $white;

    &:hover {
        background-color: $white-opacity-40 !important;
        color: $white !important;
    }
}

.button.hollow.white {
    border-color: $white;
    color: $white;

    &:hover {
        background-color: $white;
        border-color: $white !important;
        color: $grey;
        box-shadow: $hover-shadow;
    }

    &:focus:active {
        background-color: $white;
        color: $grey;
        box-shadow: inset 0 10px 20px $grey-opacity-20, inset 0 6px 6px $grey-opacity-20;
    }
}

.button.hollow.dark {
    border-color: $grey-tint-75;
    color: $grey-tint-75;

    &:hover {
        background-color: $white;
        border-color: $grey-tint-75;
        box-shadow: $hover-shadow;
    }
}

.button.hollow.danger {
    border-color: $danger;
    color: $danger;

    &:hover {
        background-color: $danger;
        border-color: $danger;
        box-shadow: $hover-shadow;
        color: $white;
    }
}

.button.large {
    padding-left: rem-calc(90);
    padding-right: rem-calc(90);
}

.button.small {
    font-size: rem-calc(16);
    padding: rem-calc(7 12);
}

.ghost-button {
    padding: rem-calc(4 5 3 5); // to make square/circle proportions
    border-radius: 100%;
    transition: all ease-in-out 0.3s;
    border: rem-calc(1) solid transparent;
    cursor: pointer;

    &__icon {
        color: $grey-tint-50;
    }

    &:hover {
        background-color: $grey-tint-50;

        .ghost-button__icon {
            color: $white !important;
        }
    }

    &:focus {
        outline: none;

        .ghost-button__icon {
            color: $duckegg;
        }
    }

    &:active:focus {
        background-color: $grey-tint-75;

        .ghost-button__icon {
            color: $white;
        }
    }
}

.tab.button {
    text-transform: uppercase;
    padding: rem-calc(2 4) !important;
    background: none;
    color: $duckegg;
    border-bottom: 0;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 3px;
    padding: 1rem !important;

    &:hover {
        color: $blueberry !important;
        margin-bottom: 0;
        border-bottom: 3px solid $blueberry;
    }
}

a.tab.button {
    &.active,
    &:focus {
        font-weight: 600;
        color: $blueberry;
        outline: none;
        border-bottom: 3px solid $blueberry;
        margin-bottom: 0;
    }
    &a:focus:active {
        font-weight: 600;
        border-bottom: 3px solid $blueberry;
        color: $blueberry;
        margin-bottom: 0;
    }
}

/* BACKGROUNDS
   ------------------------------------ */
.dark-background {
    background-color: $blueberry;
}

.bright-background {
    background-color: $duckegg;
}

.light-background {
    background-color: $spinach-tint-25;
}

.lighter-background {
    background-color: $spinach-tint-13;
}

/* ICON SETTINGS
   ------------------------------------ */
.material-icons {
    direction: ltr;
    display: inline-block;
    font-family: 'Material Icons';
    font-size: 24px;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

/* HEADINGS
   ------------------------------------ */

h1, h2, h3 {
  color: $blueberry;
  font-weight: 700;
}

h4 {
    font-weight: 700;
}

/* HEADING SIZING MIXIN
   ------------------------------------ */

@mixin h1 {
  font-size: rem-calc(30);

  @include breakpoint(medium) {
    font-size: rem-calc(42);
  }

  @include breakpoint(large) {
    font-size: rem-calc(60);
  }
}

@mixin h2 {
  font-size: rem-calc(22);

  @include breakpoint(medium) {
    font-size: rem-calc(32);
  }

  @include breakpoint(large) {
    font-size: rem-calc(40);
  }
}

@mixin h3 {
  font-size: rem-calc(20);

  @include breakpoint(medium) {
    font-size: rem-calc(28);
  }

  @include breakpoint(large) {
    font-size: rem-calc(34);
  }
}

@mixin h4 {
    font-size: rem-calc(18);

    @include breakpoint(medium) {
        font-size: rem-calc(22);
    }

    @include breakpoint(large) {
        font-size: rem-calc(26);
    }
}

@mixin h5 {
    font-size: rem-calc(18);

    @include breakpoint(medium) {
        font-size: rem-calc(22);
    }

    @include breakpoint(large) {
        font-size: rem-calc(26);
    }
}

@mixin h6 {
    font-size: rem-calc(16);

    @include breakpoint(medium) {
        font-size: rem-calc(19);
    }

    @include breakpoint(large) {
        font-size: rem-calc(22);
    }
}

/* BODY COPY SIZING MIXIN
   ------------------------------------ */
@mixin body-copy {
    font-size: rem-calc(14);

    @include breakpoint(medium) {
        font-size: rem-calc(16);
    }

    @include breakpoint(large) {
        font-size: rem-calc(18);
    }
}

@mixin body-copy--large {
    font-size: rem-calc(22);

    @include breakpoint(medium) {
        font-size: rem-calc(24);
    }

    @include breakpoint(large) {
        font-size: rem-calc(26);
    }
}

@mixin body-copy--bold {
    font-weight: 700;
}

/* Badge
   - for labeling, actionless
   ------------------------------------ */
.badge {
    font-size: rem-calc(10);
    text-transform: uppercase;
    font-weight: 400;
    padding: rem-calc(0 6);
    border-radius: 11px;

    &--primary {
        background-color: $mint-opacity-20;
        color: $mint;
    }
}

/* =============================
      GLOBAL WEBSITE SETTINGS
   =============================
   More utility CSS that isn't strictly branding
*/

/* Remove horizontal scroll bars
   (currently for the slider/contained collections which overflow)
   ------------------------------------ */
html, body {
    max-width: 100%;
    overflow-x: hidden;
}

/* Remove default tap colour
   ------------------------------------ */
* {
    -webkit-tap-highlight-color: transparent;
}


/* Hides navigation before the foundation.js file loads
   ------------------------------------ */
.no-js {
    .top-bar {
        display: none;
    }

    .hero__structure {
        opacity: 0;
    }

    .top-bar__sticky-container {
        min-height: 5.9rem;
    }

    .off-canvas {
        display: none;
    }

    .contained-card-collection__container {
        display: none;
    }

    @include breakpoint(small only) {
        .top-bar {
            display: none;
        }
    }

    @include breakpoint(medium) {
        .title-bar {
            display: none;
        }
    }
}

/* Button MIXINs
   ------------------------------------ */

   @mixin button-large {
        padding-left: rem-calc(90);
        padding-right: rem-calc(90);
    }


@mixin triangle-motif-background {
    background-image: url('../../images/background-small.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed;

    @include breakpoint(medium) {
        background-image: url('../../images/background-large.png');
        background-size: cover;
        background-position: left top;
    }
}

/* Input
   ------------------------------------ */

.input {
    &__error {
        box-shadow: 0px 0px 7px $input-error-color !important;
    }
}
