/* ==========
      Modal
   ==========
*/

@import '../_feijoa-variables.scss';

.modal {
  box-shadow: 0 0px 0px 1000px rgba( $blueberry, .8);
  width: 100%;
  height: 100%;
  // position: fixed;
  top: 0;
  margin: 25% auto;
  bottom: auto;
  margin: 0 auto;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;

  @include breakpoint(medium) {
    margin: 0;
  }

  &__inner-container {
    @include xy-grid;
    background-color: $white;
    padding: 3rem;
    flex: 0 0 auto;
    height: auto;
    flex: 0 0 100%;
    height: 100%;
    max-width: unset;
    overflow: auto;
    margin: 0;
    position: relative;

    @include breakpoint(medium) {
      flex: 0 0 auto;
      max-width: 56rem;
      height: auto;
      margin: 0 1rem;
    }

    @include breakpoint(small only) {
      padding: 1.5rem;
    }
  }

  &__heading {
    @include xy-cell();
    color: $blueberry;
    margin-bottom: 1.5rem;
    margin-left: rem-calc(0);

    @include breakpoint(small only) {
      font-size: 1.6rem;
      margin-bottom: 1rem;
      margin-top: auto;
    }
  }

  &__body {
    @include xy-cell();
    font-size: 1.2rem;
    color: $blueberry;
    margin-bottom: 0;

    @include breakpoint(small only) {
      font-size: 1rem;
    }

    @include breakpoint(small) {
      width: unset;
    }

    & p {
      margin: 0;
    }

    & div.spinner {
      background-color: $duckegg;

      & small {
        color: $white;
        display: flex;
        align-items:start;
        justify-content: left;
      }
    }

    &--large-copy {
      @include body-copy--large;
    }
  }

  &__input {
    border: 1px solid #252C26;
    padding: 1rem;
    height: 4rem;
    margin: 2rem 0;
    position: relative;


    &--number-input {
      &:before {
        content: "£";
        color: $blueberry;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &_button-wrapper {
    flex: 1;
  }

  &__button-container {
    display: flex;
    flex-direction: column;
    margin-top: rem-calc(16);
    margin-bottom: rem-calc(16);
    align-items: center;

    @include breakpoint(small only) {
      width: 100%;
    }

    @include breakpoint(medium) {
      flex-direction: row;
    }

    @include breakpoint(large) {
      margin-top: rem-calc(16);
    }

    &--reverse-order {
      flex-direction: row-reverse;

      .modal__button-wrapper {
        margin-left: 0;

        &:first-of-type {
          flex: 0 0 auto;
          margin: 0 1rem;
        }
      }
    }

    &--flex-end {
      justify-content: flex-end;
    }
  }

  &__button-wrapper {
    flex: 0 0 auto;
    margin: 0 1rem;

    &:first-of-type {
      margin-left: 0;
    }

    button {
      margin: 0;

      &:first-of-type {
        margin: 0;
      }
    }
  }

  &__footer {
    @include xy-cell();

    @include breakpoint(small) {
      margin: 0;
    }

    & .button {
      flex: 1 1 auto;
      margin: 0 1rem;
      max-width: 10rem;
      width: unset;

      &.clear {
        padding: 0.8rem 1.9rem !important;
      }

      &.wide {
        max-width: 15rem;
      }

      &.spinner {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      @include breakpoint(small only) {
        width: 100%;
        margin: 1rem 0;
      }

      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  &__close-icon {
    font-size: rem-calc(48);
    top: rem-calc(100);
    right: rem-calc(32);
    position: absolute;
    color: $duckegg;
    left: auto;
    cursor: pointer;
    font-weight: lighter;
    background-color: transparent;
    border: 0;

    @include breakpoint(medium) {
      font-size: rem-calc(45);
      top: rem-calc(16);
      right: rem-calc(32);
    }

    &:hover {
      color: $duckegg-tint-70;
    }

    &:focus:active {
      color: $duckegg-tint-70;
    }
  }

  &--hide {
    display: none;
  }

  .message {
    margin-top: 3rem;
  }
}

.message {
    padding: 1.5rem;
    width: 100%;
    display: flex;
    align-items: center;

    &__text {
        flex: 1;
        @include body-copy;
        color: $blueberry;
    }

    &__icon {
        flex: 0 0 auto;
        margin-right: 1.5rem;
    }

    &--error {
        border-left: 10px solid $danger;
        background-color: $danger-tint-20;

        .message__icon {
            color: $danger;
            font-size: 2rem;
        }
    }

    &--success {
        border-left: 10px solid $feijoa;
        background-color: $feijoa-tint-20;

        .message__icon {
            color: $feijoa;
            font-size: 2rem;
        }
    }
}

.alert {


}
