﻿/* ===========================
      INFO GROUP COLLECTION
   ===========================
*/

.info-group-collection {
    &__container {
        @include xy-grid-container;
    }

    &__inner-container {
        @include xy-grid;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        @include breakpoint(large) {
            flex-direction: row;
        }
    }
}