﻿/* ==========================
      SIGN POST COLLECTION
   ==========================
*/

.sign-post-collection {

    &__container {
        @include xy-grid-container;
    }

    &__inner-container {
        @include xy-grid;
    }

    .sign-post {
        @include xy-cell();

        @include breakpoint(medium) {
            @include xy-cell(5);
        }

        @include breakpoint(large) {
            @include xy-cell(4);

            &:last-of-type {
                @include xy-cell-offset(0);
            }
        }
    }
}