﻿/* ===============
      SIGN POST
   ===============
*/

.sign-post {
    transition: all ease-in-out 0.3s;

    @include breakpoint(small only) {
        margin: rem-calc(0 0 8);
    }

    &:hover {
        .sign-post__container {
            box-shadow: $hover-shadow;
        }
    }

    &:focus {
        outline: none;

        .sign-post__container {
            box-shadow: $hover-shadow;
        }
    }

    &__container {
        margin-left: rem-calc(-20);
        padding: rem-calc(10 20);

        @include breakpoint(small only) {
            box-shadow: $initial-shadow;
            margin-right: rem-calc(-20);
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        @include breakpoint(medium) {
            margin-left: rem-calc(-20);
            padding: rem-calc(20);
        }
    }

    &__text {
        color: $blueberry;
        margin: 0;

        @include breakpoint(small only) {
            max-width: 80%;
        }
    }

    &__button {
        margin: rem-calc(15 0 0);

        @include breakpoint(medium) {
            margin: rem-calc(30 0 0);
        }
    }

    &__chevron {
    }
}