﻿/* ===============================
      INFO GROUP
   ===============================
    - A heading and text with an optional tooltip
*/

.info-group {

    @include breakpoint(large) {
        width: rem-calc(160);
        z-index: 2;
        transition: all 0.8s;
        min-height: unset;
    }

    @include breakpoint(xlarge) {
        width: rem-calc(220);
    }

    @include breakpoint(xxlarge) {
        width: rem-calc(250);
    }

    &__inner-container {
        padding: rem-calc(20 0);
        margin: rem-calc(0 10);
        transition: all 0.8s;
        display: flex;
        align-items: center;
        justify-content: space-between;


        @include breakpoint(medium down) {
            border-bottom: $grey-tint-25 solid rem-calc(1);
        }

        @include breakpoint(medium) {
            padding: rem-calc(30 0);
            margin: rem-calc(0 15);
        }

        @include breakpoint(large) {
            position: relative;
            padding: rem-calc(30 25);
            margin: rem-calc(0);
            cursor: pointer;
            height: rem-calc(310);
            overflow: hidden;
            background-color: $white;
            border-left: $grey-tint-25 solid rem-calc(1);
        }
    }

    &__add-icon {
        color: $blueberry-tint-30;
        position: absolute;
        top: rem-calc(30);
        left: rem-calc(25);

        @include breakpoint(medium down) {
            display: none !important;
        }

        @include breakpoint(large) {
            opacity: 1;
            max-height: unset;
            // show
            transition: opacity 0.9s ease-in-out;
        }
    }

    &__heading-container {
        display: flex;
        flex-direction: column;
        align-content: center;
        padding-left: rem-calc(10);


        @include breakpoint(medium) {
            max-width: 80%;
        }

        @include breakpoint(large) {
            padding: 0;
            max-width: 90%;
        }
    }

    &__heading {
        color: $blueberry;
        margin-bottom: 0;

        @include breakpoint(large) {
            opacity: 0.7;
            // hide
            transition: opacity 0.3s ease-in-out;
        }

        p {
            display: inline-block;
            margin-right: 9px;
            margin-left: 9px;

            &:first-of-type {
                margin-left: 0;
            }
        }
    }

    &__text {
        color: $blueberry;
        margin: rem-calc(3 0 0);

        @include breakpoint(large) {
            opacity: 0;
            max-height: 0;
            // hide
            transition: opacity 0.3s ease-in-out;
        }
    }

    &__tooltip {
        margin-left: rem-calc(10);

        @include breakpoint(small only) {
            margin-right: rem-calc(10);
        }

        @include breakpoint(large) {
            opacity: 0;
            max-height: 0;
            // hide
            transition: opacity 0.3s ease-in-out;
        }

        &:focus {
            outline: none;
        }
    }

    &__tooltip-icon {
        color: $duckegg;
        font-size: rem-calc(24) !important;
        position: relative;
        top: rem-calc(1);
    }

    &--expanded {
        @include breakpoint(large) {
            width: rem-calc(500);
            box-shadow: -1px 0 0 $grey-tint-25;

            .info-group__heading {
                opacity: 1;
                // show
                transition: opacity 0.9s ease-in-out;
            }

            .info-group__add-icon {
                opacity: 0;
                max-height: 0;
                // hide
                transition: opacity 0.3s ease-in-out;
            }

            .info-group__heading-container {
                max-width: 90%;
            }

            .info-group__text {
                opacity: 1;
                max-height: unset;
                // show
                transition: opacity 0.5s ease-in-out 0.4s;
            }

            .info-group__tooltip {
                opacity: 1;
                max-height: unset;
                // show
                transition: opacity 0.5s ease-in-out 0.4s;
            }
        }
    }
}