﻿/* ===============
      ACCORDION CARD
   ===============
*/

.accordion-card {
    width: 100%;
    min-height: 6rem;
    max-height: 6rem;
    overflow: hidden;
    background-color: $white;
    box-shadow: $initial-shadow;

    &.show .accordion-card__body {
        transform: scaleY(1);
    }

    &.show {
        max-height: unset;
    }

    &__head {
        cursor: pointer;
        position: relative;
        padding: 2rem 2rem;
    }

    &__header {
        margin: 0;
        color: $blueberry;
    }

    &__body {
        padding: 2rem 2rem;
        border-top: 1px;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
        border-style: solid;
        border-color: $grey-tint-25;
        transform: scaleY(0);    
        transform-origin: top;
        transition: transform 0.25s ease-out;
    }

    &__chevron {
        display: none;
        position: absolute;
        color: $duckegg;
        top: 1.8rem;
        right: 2.4rem;
        font-size: 1.6rem;

        &.show {
            display: block;
        }
    }
}